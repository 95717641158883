import { Box, Typography } from '@material-ui/core'
import React from 'react'

const ContactDetails = (props) => {
  return (
    <div className="contactDetailsBox">
      {props.children}
    </div>
  )
}

export default ContactDetails
