import React, { useEffect, useState } from "react";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  CircularProgress,
  Typography,
  Box,
} from "@material-ui/core";
import { Countries } from "./Countries";

import axios from "axios";
import Constants from "../../../Components/Helpers/Constants";

const CountrySelector = (props) => {
  const [availablePlaces, setAvailablePlaces] = useState([]);

  // get list of counties already in the database
  const [loading, setLoading] = useState(true);
  const getPlacesInTheDB = () => {
    axios
      .get(`${Constants.api}/api/admin/shipping-county-or-country`)
      .then((r) => {
        let listInDb = [];
        r.data.shipToCountyOrCountry.map((item) => {
          listInDb.push(item.county_or_country);
        });
        // manually add some other countries that should be removed as well
        listInDb = [...listInDb, "Kenya", 'Canada', 'United States', 'Mexico'];
        let newList = [];
        // remove listInDb from Countries
        Countries.map((item) => {
          if (!listInDb.includes(item.name)) {
            newList.push(item);
          }
        });
        setAvailablePlaces(newList);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getPlacesInTheDB();
  }, []);

  const handleChange = (e) => {
    const { name, checked } = e.target;
    if (name === "selectAll") {
      let tempPlace = availablePlaces.map((availablePlace) => {
        return { ...availablePlace, isChecked: checked };
      });
      setAvailablePlaces(tempPlace);
    } else {
      let tempPlace = availablePlaces.map((availablePlace) => {
        if (availablePlace.name === name) {
          return { ...availablePlace, isChecked: checked };
        } else {
          return availablePlace;
        }
      });
      setAvailablePlaces(tempPlace);
    }
  };

  useEffect(() => {
    props.handleSelect(availablePlaces);
  }, [availablePlaces]);

  // filter the list of counties to only those that are not in the database
  if (loading) {
    return (
      <Box textAlign="center">
        <CircularProgress color="secondary" />
      </Box>
    );
  }
  return (
    <div>
      <Typography variant="h6">
        Select Destinations In The World
      </Typography>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              name="selectAll"
              onChange={handleChange}
              checked={
                availablePlaces.filter((place) => place?.isChecked !== true)
                  .length < 1
              }
            />
          }
          label="Select All"
        />
      </FormGroup>

      {availablePlaces.map((place, i) => (
        <div key={i}>
          <FormControlLabel
            control={
              <Checkbox
                name={place.name}
                onChange={handleChange}
                checked={place?.isChecked || false}
              />
            }
            label={place.name}
          />
        </div>
      ))}
    </div>
  );
};

export default CountrySelector;
