import {
  Button,
  CircularProgress,
  Divider,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import CustomSelectField from "../../../Components/GibsonsComponents/CustomSelectField";
import CustomTextField from "../../../Components/GibsonsComponents/CustomTextField";
import CountySelector from "./CountySelector";
import CountrySelector from "./CountrySelector";
import axios from "axios";
import Constants from "../../../Components/Helpers/Constants";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const Shippingzone = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState("");
  const [formErrors, setFormErrors] = useState(false);
  const [region, setRegion] = useState("kenya");
  const [places, setPlaces] = useState([]);

  const createShippingZone = (e) => {
    e.preventDefault();
    setLoading(true);
    // post shipping zone to api
    let fd = new FormData();
    fd.append("title", title);
    fd.append("region", region);
    fd.append("places", places);

    axios
      .post(`${Constants.api}/api/admin/add-shipping-zone`, fd, {
        headers: {
          Accept: "application/json",
        },
      })
      .then((r) => {
        setLoading(false);
        Swal.fire({
          title: "Shipping Zone Created",
          text: "Shipping Zone Created Successfully",
          icon: "success",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "OK",
        }).then((result) => {
          if (result.value) {
          // redirect to shipping zones
          navigate("/admin/shipping-zones/list/" + r.data.shippingZone.id);
          }
        });

      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const handleSelect = (selected) => {
    setPlaces(JSON.stringify(selected));
  };

  return (
    <>
      <Box py={1}>
        <Typography align="center" variant="h5">
          Create New Shipping Zone
        </Typography>
      </Box>
      <Divider />
      <Box py={1}>
        <form onSubmit={createShippingZone}>
          <Grid container>
            {/* select region first */}
            <Grid item xs={12}>
              <Box mb={1}>
                <CustomSelectField variant="outlined" fullWidth required>
                  <InputLabel>Select Region</InputLabel>
                  <Select
                    label="Select region"
                    value={region}
                    onChange={(e) => setRegion(e.target.value)}
                  >
                    <MenuItem value="kenya">Kenya</MenuItem>
                    <MenuItem value="rest of the world">
                      Rest Of The World
                    </MenuItem>
                  </Select>
                </CustomSelectField>
              </Box>

              <Box mb={2}>
                <CustomTextField
                  label="Title"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  onChange={(e) => setTitle(e.target.value)}
                  type="text"
                  required
                  helperText={formErrors.title}
                  error={formErrors.title ? true : false}
                />
              </Box>

              <Box mb={2}>
                {region === "kenya" ? (
                  <CountySelector handleSelect={handleSelect} />
                ) : (
                  <CountrySelector handleSelect={handleSelect} />
                )}
              </Box>

              {!loading ? (
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                >
                  Create
                </Button>
              ) : (
                <Button variant="contained" color="primary" fullWidth>
                  <CircularProgress size={24} color="secondary" />
                </Button>
              )}
            </Grid>
          </Grid>
        </form>
      </Box>
    </>
  );
};

export default Shippingzone;
