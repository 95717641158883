// import createstore from redux
import { createStore, applyMiddleware } from "redux";
// import redux thunk
import thunk from "redux-thunk";
// import reducers
import rootReducer from "./Reducers/index.js";

import { persistStore } from "redux-persist";

const middlewares = [thunk];

export const store = createStore(rootReducer, applyMiddleware(...middlewares));

export const persistor = persistStore(store);

export default {store, persistor};
