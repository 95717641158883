import { Button, Container, Grid, Link, Typography } from "@material-ui/core";
import { Facebook, Instagram, Twitter, YouTube } from "@material-ui/icons";
import { Box } from "@mui/system";
import React from "react";

const FooterLinks = ({ item }) => {
  return (
    <Grid item xs={12} sm={4}>
      <Container>
        <Box borderBottom={1} pt={2} pb={2} mb={2}>
          <Typography variant="body1">{item.sectionHeader}</Typography>
        </Box>
        {item.links.map((link) => (
          <Box key={link.title}>
            {link.icon != null ? (
              <Box mb={1}>
                <Button
                  href={link.url}
                  color="primary"
                  variant="outlined"
                  style={{ textDecoration: "none" }}
                  target="_blank"
                >
                  {link.icon}
                </Button>
                <br />
              </Box>
            ) : (
              <Link href={link.url} color="inherit" variant="subtitle2">
                {link.title}
              </Link>
            )}
          </Box>
        ))}
      </Container>
    </Grid>
  );
};

const FooterAddress = () => {
  return (
    <Grid item xs={12} sm={4}>
      <Container>
        <Box borderBottom={1} pt={2} pb={2} mb={2}>
          <Typography variant="body1">CONTACT US</Typography>
        </Box>
        <Box>
          <Typography paragraph variant="subtitle2">
            Email: info[at]gibscoffee.com
          </Typography>
          <Typography paragraph variant="subtitle2">
            Phone: +254 728 981 656 / +254 728 981 678
          </Typography>
          <Typography paragraph variant="subtitle2">
            Postal Address: Mathew Mugo,
            <br />
            Gibsons Coffee Ltd,
            <br />
            P.O. BOX 9592-00300,
            <br />
            Nairobi, Kenya.
            <br />
          </Typography>
        </Box>
      </Container>
    </Grid>
  );
};

const leftLinks = {
  sectionHeader: "SITE MAP",
  links: [
    {
      title: "Home",
      url: "/",
    },
    {
      title: "About",
      url: "/about",
    },
    {
      title: "Shop",
      url: "/shop",
    },
    {
      title: "Distributors",
      url: "/distributors",
    },
    {
      title: "Blog",
      url: "/blog",
    },
    {
      title: "Contact",
      url: "/contact",
    },
    {
      title: "Staff Mail",
      url: "https://privateemail.com/",
    },
    {
      title: "Staff Mail (old)",
      url: "https://pld109.truehost.cloud:2096/",
    }
  ],
};
const rightLinks = {
  sectionHeader: "OUR SOCIALS",
  links: [
    {
      title: "Facebook",
      url: "https://www.facebook.com/Gibson-Coffee-365333501066133/",
      icon: <Facebook fontSize="small" />,
    },
    {
      title: "Instagram",
      url: "https://www.instagram.com/gibsonscoffee_kenya",
      icon: <Instagram fontSize="small" />,
    },
    {
      title: "Twitter",
      url: "https://twitter.com/gibsons_coffee",
      icon: <Twitter fontSize="small" />,
    },
    {
      title: "Youtube",
      url: "https://www.youtube.com/channel/UCdzKuyWU0SZiKfhv5aZO-sQ",
      icon: <YouTube fontSize="small" />,
    },
  ],
};

const Footer = () => {
  return (
    <footer
      style={{
        position: "sticky",
        top: "100%",
      }}
    >
      <Box style={{ background: "#2A2323", color: "#EEEEEE" }}>
        <Container maxWidth="lg">
          <Grid container>
            <FooterLinks item={leftLinks} />
            <FooterLinks item={rightLinks} />
            <FooterAddress />
          </Grid>
        </Container>
        <Box
          textAlign="center"
          pt={{ xs: 2, sm: 2 }}
          pb={{ xs: 2, sm: 2 }}
          borderTop={1}
          borderColor="#423d3d"
        >
          Gibsons Coffee &copy; {new Date().getFullYear()} | Crafted by: Shoply
          Africa
        </Box>
      </Box>
    </footer>
  );
};

export default Footer;
