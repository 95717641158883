import {
  Button,
  CircularProgress,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Typography,
} from "@material-ui/core";
import { Box } from "@mui/system";
import axios from "axios";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import CustomTextField from "../../../Components/GibsonsComponents/CustomTextField";
import Constants from "../../../Components/Helpers/Constants";

import modalStyle from "../../../Styles/ModalStyles";
import CustomSelectField from "../../../Components/GibsonsComponents/CustomSelectField";

const AddProductsToZone = (props) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState("");
  const [selectedProductsFee, setSelectedProductsFee] = useState("");

  const submitProduct = (e) => {
    setSubmitting(true);
    e.preventDefault();

    let oldProducts = [...products];
    let newProducts = [];
    oldProducts.forEach((product) => {
      if (product.id != selectedProduct) {
        newProducts.push(product);
      }
    });

    setProducts(newProducts);

    addProductToShippingzone(selectedProductsFee, selectedProduct);

    setSelectedProduct("");
    setSelectedProductsFee("");
    e.target.reset();
  };

  const addProductToShippingzone = (shippingFee, productId) => {
    const fd = new FormData();
    fd.append("productId", productId);
    fd.append("shippingFee", shippingFee);
    fd.append("shippingzone", props.shippingzone.id);

    axios
      .post(`${Constants.api}/api/admin/add-product-to-shippingzone`, fd, {
        headers: {
          Accept: "application/json",
        },
      })
      .then((r) => {
        props.getProductsInShippingzone();
        setSubmitting(false);
      })
      .catch((error) => {
        console.log(error);
        setSubmitting(false);
      });
  };

  const getProducts = () => {
    axios
      .get(
        `${Constants.api}/api/admin/products/region/${props.shippingzone.region}`,
        { Accept: "application/json" }
      )
      .then((r) => {
        // console.log(r.data);
        let newListOfProducts = r.data.filter((product) => {
          return !props.productsInShippingzone.some(
            (item) => item.product_id === product.id
          );
        });
        // console.log("newListOfProducts", newListOfProducts);
        setProducts(newListOfProducts);
      })
      .then(() => {
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const loadProducts = () => {
    handleOpen();
    getProducts();
  };

  useEffect(() => {
    props.getProductsInShippingzone();
  }, []);

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        disableElevation
        onClick={loadProducts}
      >
        Add Product To Zone
      </Button>

      <Modal
        open={open}
        onClose={handleClose}
        style={{
          position: "absolute",
          top: "10%",
          left: "10%",
          overflow: "scroll",
          height: "100%",
          display: "block",
        }}
      >
        <Box sx={modalStyle}>
          {loading ? (
            <Box textAlign="center">
              <CircularProgress color="primary" />
            </Box>
          ) : (
            <>
              {products.length <= 0 ? (
                <Box textAlign="center">
                  <Typography variant="h6">
                    All products are already in this shipping zone.
                  </Typography>
                  {/* small caption to say user can edit now */}
                  <Typography variant="caption">
                    You can edit the shipping fee of each product in the
                    shipping zone.
                  </Typography>
                </Box>
              ) : (
                <>
                  <Box my={2}>
                    <form onSubmit={submitProduct}>
                      <Grid container>
                        <Grid item xs={12}>
                          <CustomSelectField
                            variant="outlined"
                            fullWidth
                            required
                          >
                            <InputLabel>Product</InputLabel>
                            <Select
                              label="Product"
                              value={selectedProduct}
                              onChange={(e) => {
                                setSelectedProduct(e.target.value);
                              }}
                            >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              {products.map((product, index) => (
                                <MenuItem value={product.id} key={product.id}>
                                  {product.title}
                                </MenuItem>
                              ))}
                            </Select>
                          </CustomSelectField>
                        </Grid>

                        <Grid item xs={12}>
                          <Box my={4}>
                            <CustomTextField
                              name="shipping_fee"
                              label={`Shipping Fee`}
                              variant="outlined"
                              size="medium"
                              fullWidth
                              margin="none"
                              type="number"
                              inputProps={{ step: ".01" }}
                              required
                              onChange={(e) =>
                                setSelectedProductsFee(e.target.value)
                              }
                            />
                          </Box>
                        </Grid>

                        <Grid item xs={12}>
                          <Button
                            type="submit"
                            size="small"
                            color="primary"
                            variant="contained"
                            fullWidth
                          >
                            {submitting ? (
                              <CircularProgress color="secondary" />
                            ) : (
                              <>Submit</>
                            )}
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  </Box>
                </>
              )}
            </>
          )}
        </Box>
      </Modal>
    </>
  );
};

export default AddProductsToZone;
