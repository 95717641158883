import {
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import React, { useEffect, useState } from "react";
import Constants from "../../../Components/Helpers/Constants";

const ViewUsers = () => {
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState(null);

  function getUsers() {
    axios
      .get(`${Constants.api}/api/admin/users`, {
        Accept: "application/json",
      })
      .then((r) => {
        setUsers(r.data[0]);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  }

  useEffect(() => {
    getUsers();
  }, []);


  if (loading) {
    return (
      <>
        <Skeleton animation="wave" />
        <Skeleton animation="wave" />
        <Skeleton animation="wave" />
      </>
    );
  } else {
    return (
      <div>
        <Box>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 350 }}>
              <TableHead>
                <TableRow sx={{ textTransform: "uppercase" }}>
                  <TableCell>#</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell align="right">Email</TableCell>
                  <TableCell align="right">Role</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users.map((user,i)=>(

                <TableRow key={i}>
                  <TableCell>{++i}.</TableCell>
                  <TableCell sx={{textTransform: "uppercase"}}>{user.first_name} {user.last_name}</TableCell>
                    <TableCell align="right">{user.email}</TableCell>
                    <TableCell align="right">
                      <Typography variant="button" sx={{background: "#EEE", padding: 1,}}>{user.role}</Typography>
                    </TableCell>
                </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </div>
    );
  }
};

export default ViewUsers;
