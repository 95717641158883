import { makeStyles, Typography, Container, Grid } from "@material-ui/core";
import React from "react";
import IndexCarousel from "./IndexCarousel";
import { Box } from "@mui/system";
import Testimonials from "./Testimonials";
import VideoComponent from "./VideoComponent";

const useStyles = makeStyles((theme) => {
  return {
    yellow: {
      background: "yellow",
    },
  };
});

const Index = () => {
  const classes = useStyles();

  return (
    <>
      <IndexCarousel />
      <div className="aboutGibsonsVideosWrapper">
        <Container>
          <Box
            sx={{
              pt: "4rem",
              pb: "2rem", // Increase padding-bottom for better spacing
              textAlign: "center", // Center align text
            }}
          >
            <Typography
              variant="h4"
              style={{ color: "#F8931D", marginBottom: "1rem" }}
            >
              GIBSONS COFFEE
            </Typography>
            <Typography
              variant="h5"
              style={{ color: "#EEEEEE", marginBottom: "1rem" }}
            >
              AS SEEN ON TV
            </Typography>
            <Typography
              variant="body1" // Use a body text variant for better readability
              style={{
                color: "#EEEEEE",
                marginBottom: "1.5rem",
                maxWidth: "30rem",
                margin: "0 auto",
              }} // Adjust margin and width
            >
              Join us on a visual exploration of Gibsons Coffee's rich heritage and passion for perfection. These documentaries provide a front-row seat to our dedication, from sourcing the finest beans to the perfect cup. 
              <br />
              It's more than just coffee; it's an experience.
            </Typography>
          </Box>

          <Box
            py={2}
            display="flex"
            flexDirection={{ xs: "column", lg: "row" }}
            alignItems="center"
            justifyContent="space-evenly"
            sx={{ minHeight: "70vh", pb: "4rem" }}
          >
            <VideoComponent videoId="Wyhro1groG8" imageUrl="/gibsons-video-thumbnail-orange.png" />
            <VideoComponent videoId="jM9WxCJ7wPA" imageUrl="/gibsons-video-thumbnail-white.png" />
            
          </Box>
        </Container>
      </div>
      <Testimonials />
    </>
  );
};

export default Index;
