import { Button, Divider, Grid, Link, Typography } from "@material-ui/core";
import { Box } from "@mui/system";
import axios from "axios";
import React from "react";
import Swal from "sweetalert2";
import CustomTextField from "../../../Components/GibsonsComponents/CustomTextField";
import Constants from "../../../Components/Helpers/Constants";

const ProductsShippingZone = (props) => {
  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Confirm if you want to remove product from shipping zone!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if(result.isConfirmed) {
        const fd = new FormData();
        fd.append("productId", id);
        axios
          .post(
            `${Constants.api}/api/admin/remove-product-from-shippingzone`,
            fd,
            {
              headers: {
                Accept: "application/json",
              },
            }
          )
          .then((r) => {
            props.getProductsInShippingzone();
            Swal.fire({
              title: "Success",
              text: "Product was successfully removed from shipping zone",
              icon: "success",
              confirmButtonText: "Ok",
            });
          })
          .catch((error) => {
            Swal.fire({
              title: "Error",
              text: "Something went wrong.",
              icon: "error",
              confirmButtonText: "Ok",
            });
          });
      }

    });
  };

  const submitProduct = (e) => {
    e.preventDefault();
    let productIdValue = e.target.elements.product_id.value;
    let shippingFeeValue = e.target.elements.shipping_fee.value;
    addProductToShippingzone(shippingFeeValue, productIdValue);
  };

  const addProductToShippingzone = (shippingFee, productId) => {
    const fd = new FormData();
    fd.append("productId", productId);
    fd.append("shippingFee", shippingFee);
    fd.append("shippingzone", props.shippingzone.id);

    console.log(productId, shippingFee, props.shippingzone.id);
    axios
      .post(`${Constants.api}/api/admin/add-product-to-shippingzone`, fd, {
        headers: {
          Accept: "application/json",
        },
      })
      .then((r) => {
        props.getProductsInShippingzone();
        Swal.fire({
          title: "Success",
          text: "Product shipping rate has been updated.",
          icon: "success",
          confirmButtonText: "Ok",
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: "Something went wrong.",
          icon: "error",
          confirmButtonText: "Ok",
        });
      });
  };

  return (
    <>
      <Box py={1}>
        <Typography variant="h5" align="center">
          Products in Shipping Zone
        </Typography>
      </Box>
      <Divider />
      <Box py={1}>
        <Grid container>
          <Grid item xs={4}>
            <Typography variant="h6">Title</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="h6">Shipping Fee</Typography>
          </Grid>
          <Grid item xs={2} align="right">
            <Typography variant="h6">Edit</Typography>
          </Grid>
          <Grid item xs={2} align="right">
            <Typography variant="h6">Delete</Typography>
          </Grid>
        </Grid>

        {props.productsInShippingzone.map((product) => (
          <Box key={product.id} my={2}>
            <form onSubmit={submitProduct}>
              <Grid container>
                <Grid item xs={4}>
                  <Typography variant="body1">
                    {product.product.title}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Box mx={1}>
                    <CustomTextField
                      name="shipping_fee"
                      label={`Shipping Fee ${product.product.currency}`}
                      variant="outlined"
                      size="small"
                      margin="none"
                      type="number"
                      inputProps={{ step: ".01" }}
                      required
                      defaultValue={product.rate}
                    />
                  </Box>
                </Grid>

                <Grid item xs={2} align="right">
                  <input
                    type="hidden"
                    name="product_id"
                    value={product.product.id}
                  />
                  <Button type="submit" variant="contained" color="primary">
                    Edit
                  </Button>
                </Grid>

                <Grid item xs={2} align="right">
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => handleDelete(product.product.id)}
                  >
                    Delete
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
        ))}
      </Box>
    </>
  );
};

export default ProductsShippingZone;
