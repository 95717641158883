import React from "react";
import { Typography, Container, Grid } from "@material-ui/core";
import { Box } from "@mui/system";

const EthicalProduction = () => {
  return (
    <div className="ethicalProductionWrapper">
      <Container>
        <Box py={4}>
          <Grid container>
            <Grid item sm={12} md={6} style={{ display: "flex" }}>
              <Box p={4} style={{ display: "flex" }}>
                <img
                  src="/images/direct-trade-bg.jpg"
                  width="100%"
                  style={{ margin: "auto", display: "block" }}
                />
              </Box>
            </Grid>
            <Grid item sm={12} md={6}>
              <h1 className="text-center mt-3 mb-3 uppercase">
                Our Commitment to Ethical Coffee
              </h1>
              <section>
                <h4>Building Relationships:</h4>
                <p>
                  At Gibsons Coffee, we're dedicated to fostering mutually
                  beneficial and respectful relationships with the individual
                  producers and cooperatives we proudly partner with.
                </p>
              </section>
              <section>
                <h4>Direct Sourcing:</h4>
                <p>
                  We cut out the traditional middleman buyers and sellers by
                  purchasing our coffee directly from the growers themselves.
                  This not only ensures quality but also supports the
                  hardworking farmers who grow our beloved beans.
                </p>
              </section>
              <section>
                <h4>Quality Assurance:</h4>
                <p>
                  Our commitment to ethical production extends to the very farms
                  where our coffee beans are cultivated. We meticulously inspect
                  these farms to guarantee that they adhere to the highest
                  standards of "healthy environmental practices." This includes
                  assessing their use of herbicides and pesticides, as well as
                  responsible wastewater disposal, among other critical factors.
                </p>
              </section>
              <section>
                <h4>Fair Labor Standards:</h4>
                <p>
                  Gibsons Coffee believes in fair compensation for the growers
                  who contribute to our exceptional brews. We consistently
                  ensure that our payments are at or above market rates,
                  empowering the individuals behind the beans.
                </p>
              </section>
              <section>
                <h4>Unparalleled Coffee Quality:</h4>
                <p>
                  Our dedication to ethical sourcing and rigorous quality
                  standards has earned us the trust of millions of coffee
                  aficionados who have chosen Gibsons Coffee for their daily
                  brew. Join us on this journey to savor the finest coffee
                  quality that's been cherished by our loyal customers for
                  years.
                </p>
              </section>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </div>
  );
};

export default EthicalProduction;
