import { TextField } from "@material-ui/core";
import { styled } from "@material-ui/styles";



const CustomTextField = styled(TextField)
(({ theme }) => ({

  "& label.Mui-focused": {
    color: theme.palette.primary.main,
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "green",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: theme.palette.secondary.light,
    },
    /* "&:hover fieldset": {
      borderColor: "yellow",
    },
    "&.Mui-focused fieldset": {
      borderColor: "green",
    }, */
  },

}));

export default CustomTextField;
