import React from "react";
import {
  Box,
  Divider,
  Typography,
  Card,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@material-ui/core";

const style = {
  container: {
    padding: '16px',
    textAlign: 'center',
  },
  boldText: {
    fontWeight: 'bold',
  },
};

const CustomerWrapper = (props) => {
  const { order } = props;
  

  return (
    <>
      <Box py={1}>
        <Typography align="center" variant="h5">
          Customer Details
        </Typography>
      </Box>
      <Divider />
      <Box py={1} style={style.container}>
      <Typography align="center" variant="h6">
        <span style={style.boldText}>Customer Name:</span> {`${order.user.first_name ?? 'N/A'} ${order.user.last_name ?? 'N/A'}`}
      </Typography>
      <Typography align="center" variant="body1">
        <span style={style.boldText}>Email:</span> {order.user.email ?? 'N/A'}
      </Typography>
      <Typography align="center" variant="body1">
        <span style={style.boldText}>Phone:</span> {order.user.phone ?? 'N/A'}
      </Typography>
      <Typography align="center" variant="body1">
        <span style={style.boldText}>Address:</span> {order.user.building ?? 'N/A'}
      </Typography>
      <Typography align="center" variant="body1">
        <span style={style.boldText}>Country/County:</span> {order.user.country ?? 'N/A'}
      </Typography>
    </Box>
    </>
  );
};

export default CustomerWrapper;
