import { Grid } from "@material-ui/core";
import { Box } from "@mui/system";
import React, { useRef } from "react";
import allStyles from "../../Styles/allStyles";
import Constants from "../Helpers/Constants";
import ImagePlaceHolder from "./ImagePlaceHolder";

const ProductGallery = (props) => {
  const classes = allStyles();
  const imgRef = useRef();

  function changeImg(url) {
    imgRef.current.src = url;
  }

  return (
    <>
      <Box align="center">
        {props.imgs.length == 0 ? (
          <ImagePlaceHolder width="100%" hTop={20} hBottom={70} />
        ) : (
          <img
            src={`${Constants.api + props.imgs[0].file_name}`}
            className={classes.productImage}
            ref={imgRef}
          />
        )}
      </Box>
      <Grid container justifyContent="center">
        {props.imgs.map((imgItem, index) => (
          <Grid item key={index}>
            <Box
              m={1}
              style={{
                backgroundImage:
                  "url(" + Constants.api + imgItem.file_name + ")",
              }}
              className={classes.productIcons}
              onClick={() => changeImg(Constants.api + imgItem.file_name)}
            ></Box>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default ProductGallery;
