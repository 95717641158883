import React, { useEffect } from 'react'
import Helpers from '../Helpers/Helpers';
import { useNavigate } from "react-router-dom";

const ProtectedRoutes = ({component}) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (Helpers.isLoggedIn()) navigate("/cart");
  }, []);
  
  return (
    <div>
      {component}
    </div>
  )
}

export default ProtectedRoutes
