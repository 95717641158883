import { Box } from "@mui/system";
import React from "react";
import CustomTextField from "../../Components/GibsonsComponents/CustomTextField";

const DeliveryDetailsForm = (props) => {
  return (
    <>
      <Box pt={2}>
        <CustomTextField
          label="First Name"
          name="firstName"
          type="text"
          required
          fullWidth
          variant="outlined"
          size="small"
          margin="none"
          onChange={(e) =>
            props.setUser({ ...props.user, first_name: e.target.value })
          }
          value={props.user?.first_name ?? ""}
        />
      </Box>
      <Box pt={2}>
        <CustomTextField
          label="Last Name"
          name="lastName"
          type="text"
          required
          fullWidth
          variant="outlined"
          size="small"
          margin="none"
          onChange={(e) =>
            props.setUser({ ...props.user, last_name: e.target.value })
          }
          value={props.user?.last_name ?? ""}
        />
      </Box>
      <Box pt={2}>
        <CustomTextField
          label="Email"
          name="email"
          type="email"
          required
          fullWidth
          variant="outlined"
          size="small"
          margin="none"
          onChange={(e) =>
            props.setUser({ ...props.user, email: e.target.value })
          }
          value={props.user?.email ?? ""}
        />
      </Box>
      <Box pt={2}>
        <CustomTextField
          label="Phone"
          name="phone"
          type="text"
          required
          fullWidth
          variant="outlined"
          size="small"
          margin="none"
          onChange={(e) =>
            props.setUser({ ...props.user, phone: e.target.value })
          }
          value={props.user?.phone ?? ""}
        />
      </Box>
      <Box pt={2}>
        <CustomTextField
          label="Town/Address"
          name="building"
          type="text"
          required
          fullWidth
          variant="outlined"
          size="small"
          margin="none"
          multiline
          minRows={6}
          onChange={(e) =>
            props.setUser({ ...props.user, building: e.target.value })
          }
          value={props.user?.building ?? ""}
        />
      </Box>
    </>
  );
};

export default DeliveryDetailsForm;
