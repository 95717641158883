import {
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import { Skeleton } from "@mui/material";

import { Box } from "@mui/system";
import axios from "axios";
import React, { useState, useEffect } from "react";
import CustomSelectField from "../../../Components/GibsonsComponents/CustomSelectField";
import CustomTextField from "../../../Components/GibsonsComponents/CustomTextField";
import Constants from "../../../Components/Helpers/Constants";
import { useNavigate } from "react-router-dom";
import { useQuill } from 'react-quilljs';
import 'quill/dist/quill.snow.css';

const AddProduct = () => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("");
  const [units, setUnits] = useState("");
  const [unitsValue, setUnitsValue] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [minQty, setMinQty] = useState("");
  const [region, setRegion] = useState("kenya");
  const [currency, setCurrency] = useState("kshs");
  const [shown, setShown] = useState(true);
  const [loading, setLoading] = useState(false);
  const [formErrors, setFormErrors] = useState(false); // TODO
  const [categories, setCategories] = useState(null);
  const [ready, setReady] = useState(false);
  const navigate = useNavigate();
  const { quill, quillRef } = useQuill();

  function createProduct(e) {
    e.preventDefault();
    const fd = new FormData();
    fd.append("title", title);
    fd.append("description", description);
    fd.append("price", price);
    fd.append("units", units);
    fd.append("units_value", unitsValue);
    fd.append("category_id", categoryId);
    fd.append("min_qty", minQty);
    fd.append("region", region);
    fd.append("currency", currency);
    fd.append("shown", shown);
    setLoading(true);
    axios
      .post(`${Constants.api}/api/admin/add-product`, fd, {
        headers: {
          Accept: "application/json",
        },
      })
      .then((r) => {
        navigate(`/admin/product/${r.data[0].id}`);
      })
      .catch((error) => {
        setFormErrors(error.response.data.error);
        setLoading(false);
      });
  }

  function getNeededItems() {
    // 1. Get categories
    axios
      .get(`${Constants.api}/api/admin/categories`, {
        Accept: "application/json",
      })
      .then((r) => {
        setCategories(r.data[0]);
        setReady(true);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  useEffect(() => {
    getNeededItems();
  }, []);

  useEffect(() => {
    if (quill) {
      quill.clipboard.dangerouslyPasteHTML(''); // <- this is where the initial HTML string can be passed

      quill.on('text-change', (delta, oldDelta, source) => {
        // console.log('Text change!');
        // console.log(quill.getText()); // Get text only
        // console.log(quill.getContents()); // Get delta contents
        // console.log(quill.root.innerHTML); // Get innerHTML using quill <- This is what you want to send to DB
        // console.log(quillRef.current.firstChild.innerHTML); // Get innerHTML using quillRef
        setDescription(quill.root.innerHTML);
      });
    }
  }, [quill]);

  if (!ready) {
    return (
      <>
        <Skeleton animation="wave" />
        <Skeleton animation="wave" />
        <Skeleton animation="wave" />
      </>
    );
  } else {
    return (
      <div>
        <Box py={1}>
          <Typography align="center" variant="h5">
            Add Product
          </Typography>
        </Box>
        <Divider />
        <Box py={1}>
          <form onSubmit={createProduct}>
            <Grid container>
              <Grid item xs={12} md={6}>
                <Box px={1}>
                  <CustomTextField
                    label="Title"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    onChange={(e) => setTitle(e.target.value)}
                    type="text"
                    required
                    helperText={formErrors.title}
                    error={formErrors.title ? true : false}
                  />

                  <CustomSelectField variant="outlined" fullWidth required>
                    <InputLabel>Region</InputLabel>
                    <Select
                      label="Region"
                      value={region}
                      onChange={(e) => {
                        setRegion(e.target.value);
                      }}
                    >
                      <MenuItem value={"kenya"}>Kenya</MenuItem>
                      <MenuItem value={"rest of the world"}>
                        Rest of the world
                      </MenuItem>
                    </Select>
                  </CustomSelectField>

                  <CustomTextField
                    label="Price"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    onChange={(e) => setPrice(e.target.value)}
                    type="number"
                    inputProps={{
                      step: ".01",
                    }}
                    required
                    helperText={formErrors.price}
                    error={formErrors.price ? true : false}
                  />

                  <CustomSelectField variant="outlined" fullWidth required>
                    <InputLabel>Currency</InputLabel>
                    <Select
                      label="Currency"
                      value={currency}
                      onChange={(e) => setCurrency(e.target.value)}
                    >
                      <MenuItem value={"kshs"}>Kshs</MenuItem>
                      <MenuItem value={"usd"}>USD</MenuItem>
                    </Select>
                  </CustomSelectField>
                  <FormGroup>
                    <FormControlLabel
                    control={
                    <Checkbox
                    defaultChecked
                    onChange={(e) => {
                      setShown(e.target.checked);
                    }}
                    />
                    }
                    label="Show product"
                    />
                  </FormGroup>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box px={1}>
                  <CustomTextField
                    label="Minimum Qty"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    onChange={(e) => setMinQty(e.target.value)}
                    type="number"
                    helperText={formErrors.min_qty}
                    error={formErrors.min_qty ? true : false}
                  />

                  <CustomSelectField variant="outlined" fullWidth>
                    <InputLabel>Category</InputLabel>
                    <Select
                      label="Units"
                      value={categoryId}
                      onChange={(e) => setCategoryId(e.target.value)}
                    >
                      {categories.map((category) => (
                        <MenuItem key={category.id} value={category.id}>
                          {category.title}
                        </MenuItem>
                      ))}
                    </Select>
                  </CustomSelectField>

                  <CustomSelectField variant="outlined" fullWidth>
                    <InputLabel>Units</InputLabel>
                    <Select
                      label="Units"
                      value={units}
                      onChange={(e) => setUnits(e.target.value)}
                    >
                      <MenuItem value={"kg"}>kg</MenuItem>
                      <MenuItem value={"gm"}>gm</MenuItem>
                      <MenuItem value={"mg"}>mg</MenuItem>
                      <MenuItem value={"lbs"}>lbs</MenuItem>
                      <MenuItem value={"pack"}>pack</MenuItem>
                      <MenuItem value={"piece"}>piece</MenuItem>
                      <MenuItem value={"unit"}>unit</MenuItem>
                      <MenuItem value={"litre"}>litre</MenuItem>
                      <MenuItem value={"ml"}>ml</MenuItem>
                    </Select>
                  </CustomSelectField>

                  <CustomTextField
                    label="Units value"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    onChange={(e) => setUnitsValue(e.target.value)}
                    type="number"
                    helperText={formErrors.units_value}
                    error={formErrors.units_value ? true : false}
                  />
                  
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Box px={1}>
                  {/* <CustomTextField
                    label="Description"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    multiline
                    minRows={6}
                    onChange={(e) => setDescription(e.target.value)}
                    type="text"
                    required
                    helperText={formErrors.description}
                    error={formErrors.description ? true : false}
                  /> */}

                  <Box my={2}>
                    <label htmlFor="description">Description</label>
                    <div style={{ width: "100%" }}>
                      <div ref={quillRef} />
                    </div>
                  </Box>
                  
                </Box>
              </Grid>
            </Grid>
            <Box textAlign="center" py={2}>
              {loading ? (
                <CircularProgress size={24} />
              ) : (
                <>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    type="submit"
                  >
                    SUBMIT
                  </Button>
                </>
              )}
            </Box>
          </form>
        </Box>
      </div>
    );
  }
};

export default AddProduct;
