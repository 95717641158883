import { Button } from "@mui/material";
import axios from "axios";
import React from "react";
import Swal from "sweetalert2";
import Constants from "../../../Components/Helpers/Constants";

const DeleteImage = (props) => {
  // DELETE photo
  function deletePhoto() {
    Swal.fire({
      title: "Are you sure?",
      text: "Confirm if you want to permanently delete!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .get(
            `${Constants.api}/api/admin/product/image/delete/${props.image.id}`,
            {
              Accept: "application/json",
            }
          )
          .then((r) => {
            console.log(r.data);
            props.getProductImages();
            Swal.fire("Deleted!", "Your file has been deleted.", "success");
          })
          .catch((e) => {
            console.log(e.response.data);
          });

      }
    });
  }

  return (
    <>
      <Button onClick={() => deletePhoto(props.image.id)}>Remove</Button>
    </>
  );
};

export default DeleteImage;
