import { Container, Grid, Typography } from "@material-ui/core";
import React from "react";
import { Box } from "@mui/system";

const History = () => {
  return (
    <>
      <div className="historyWrapper">
        <Container>
          <Grid container justify="center" alignItems="center">
            {/*  centered grid item of 5 */}
            <Grid
              item
              xs={12}
              sm={12}
              md={9}
              style={{ background: "rgba(19, 13, 27, 0.8)", color: "#FFF" }}
            >
              <Box px={4} py={2}>
                <Typography variant="h3" color="primary" align="center">
                  History
                </Typography>

                <p>
                  While coffee first made its debut in Kenya in 1893, the seeds
                  of Gibsons Coffee were sown in the early 1960s by our father,
                  Gibson, shortly after Kenya gained independence from British
                  rule.
                </p>
                <p>
                  On a humble five-acre plot, our father planted the inaugural
                  Arabica coffee trees of the SL34/SL28 variety on pristine
                  land, resulting in coffee of unparalleled quality that would
                  captivate the world's palate. Now, half a century later, we
                  continue to produce this exceptional coffee on hundreds of
                  acres of land. Our farms are nestled on the slopes of Mount
                  Kenya, just a stone's throw from the equator.
                </p>
                <p>
                  Our coffee thrives in the nutrient-rich volcanic soil,
                  nourished by the optimal climatic conditions of the region.
                  Every Gibson's coffee bean is meticulously handpicked, sorted,
                  and subjected to a process of wet processing and sun drying
                  that we carefully monitor and control. This method ensures
                  that the resulting cup of coffee boasts extraordinary acidity
                  and flavor, a profound aroma, a full-bodied profile, and an
                  aftertaste replete with floral notes. Once processed at the
                  farm level, the parchment beans proceed to a milling facility
                  where the husk is expertly removed, and the resulting green
                  beans are mechanically graded into seven categories based on
                  size, shape, and weight.
                </p>
                <p>
                  Gibsons Coffee Limited holds the honor of being licensed by
                  the Kenyan Government to both cultivate and market our coffee
                  domestically and internationally. We roast and package our
                  coffee under the Gibsons Coffee brand and distribute it
                  locally through major supermarkets, mini markets, shops, and
                  kiosks. Additionally, we proudly export our branded coffee
                  worldwide through selected distributors in various countries.
                </p>
                {/* <p>
                  Furthermore, we've extended our presence in the coffee
                  industry by operating coffeehouses in the heart of our capital
                  city, providing a complete coffee value chain experience.
                </p> */}
                <p>
                  For those interested in becoming our distributors in their
                  respective countries, we invite you to reach out to us for
                  further discussions on this exciting opportunity.
                </p>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
};

export default History;
