// cart reducer

const initialState = {
  items: [],
  cartCount: 0,
  cartTotal: 0,
};

const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_TO_CART":
      // check if item is already in cart
      const itemInCart = state.items.find(
        (item) => item.id === action.payload.product.id
      );

      let qtyToAdd = action.payload.qty;

      if (itemInCart) {
        // if item is already in cart, increase quantity
        return {
          ...state,
          items: state.items.map((item) =>
            item.id === action.payload.product.id
              ? { ...item, quantity: item.quantity + qtyToAdd }
              : item
          ),
          cartCount: state.cartCount + qtyToAdd,
          cartTotal: state.cartTotal + action.payload.product.price * qtyToAdd,
        };
      } else {
        // if item is not in cart, add item to cart

        // check that qty to add is not lower than item's min_qty
        if (qtyToAdd < action.payload.product.min_qty)
          qtyToAdd = action.payload.product.min_qty;
        return {
          ...state,
          // set item with quantity
          items: [
            ...state.items,
            {
              ...action.payload.product,
              quantity: qtyToAdd,
            },
          ],
          cartCount: state.cartCount + qtyToAdd,
          cartTotal: state.cartTotal + action.payload.product.price * qtyToAdd,
        };
      }

    // remove from cart
    case "DELETE_PRODUCT":
      return {
        ...state,
        items: state.items.filter(
          (item) => item.id !== action.payload.product.id
        ),
        cartCount: state.cartCount - action.payload.product.quantity,
        cartTotal:
          state.cartTotal -
          action.payload.product.quantity * action.payload.product.price,
      };

    // reduce qty from cart
    case "REDUCE_FROM_CART":
      // if item.quantity - 1 is less than action.payload.product.min_qty, remove item from cart
      if (
        action.payload.product.quantity - 1 <
        action.payload.product.min_qty
      ) {
        return {
          ...state,
          items: state.items.filter(
            (item) => item.id !== action.payload.product.id
          ),
          cartCount: state.cartCount - action.payload.product.quantity,
          cartTotal:
            state.cartTotal -
            action.payload.product.quantity * action.payload.product.price,
        };
      }

      return {
        ...state,
        items: state.items.map(
          (item) =>
            item.id === action.payload.product.id
              ? { ...item, quantity: item.quantity - 1 }
              : item // if item is not in cart, return item
        ),
        cartCount: state.cartCount - 1,
        cartTotal: state.cartTotal - action.payload.product.price,
      };

    case "EMPTY_CART":
      return {
        ...state,
        items: [],
        cartCount: 0,
        cartTotal: 0,
      };
    default:
      return state;
  }
};

export default cartReducer;
