import {
  Divider,
  Modal,
  Button,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import { Skeleton } from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
// import { Button } from "@mui/material";

import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import CustomTextField from "../../../Components/GibsonsComponents/CustomTextField";
import Constants from "../../../Components/Helpers/Constants";
import modalStyle from "../../../Styles/ModalStyles";

const EditCategory = (props) => {
  const [openModal, setOpenModal] = useState(false);
  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);

  const [formErrors, setFormErrors] = useState(false);
  const [title, setTitle] = useState(props.category.title);
  const [loadingCategoryDetails, setLoadingCategoryDetails] = useState(true);
  const [loading, setLoading] = useState(false);

  function fetchCategory(id) {
    axios
      .get(`${Constants.api}/api/admin/category/${id}`, {
        Accept: "application/json",
      })
      .then((r) => {
        // console.log(r.data[0]);
        setLoadingCategoryDetails(false);
      })
      .catch((error) => {
        console.log(error.response.data);
        if (typeof error.response.data != undefined) {
          setFormErrors(error.response.data.error);
        }
      });
  }

  function editCategory(id) {
    handleOpen();
    console.log("editing category id:" + id);
    fetchCategory(id);
  }

  function submitEditCategory(e) {
    e.preventDefault();
    setLoading(true);
    setFormErrors(false);

    const fd = new FormData();
    fd.append("id", props.category.id);
    fd.append("title", title);

    axios
      .post(`${Constants.api}/api/admin/edit-category`, fd, {
        headers: {
          Accept: "application/json",
        },
      })
      .then((r) => {
        console.log(r.data);
        setLoading(false);
        props.getCategories();
      })
      .then(() => {
        handleClose();
      })
      .then(() => {
        Swal.fire("Success!", "Successfully edited.", "success");
      })
      .catch((error) => {
        setFormErrors(error.response.data.error);
        setLoading(false);
      });
  }

  return (
    <>
      <Button
        onClick={() => editCategory(props.category.id)}
        color="primary"
        variant="outlined"
        size="small"
      >
        Edit
      </Button>

      <Modal open={openModal} onClose={handleClose}>
        <Box sx={modalStyle}>
          <Typography variant="h5" align="center">
            Edit category
          </Typography>
          <Box py={2}>
            <Divider />
          </Box>

          {loadingCategoryDetails ? (
            <>
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
            </>
          ) : (
            <form onSubmit={submitEditCategory}>
              <CustomTextField
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                label="Title"
                variant="outlined"
                fullWidth
                margin="normal"
                type="text"
                required
                helperText={formErrors.title}
                error={formErrors.title ? true : false}
              />

              <Box py={2}>
                <Divider />
              </Box>

              {loading ? (
                <CircularProgress size={24} />
              ) : (
                <>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    type="submit"
                  >
                    SUBMIT
                  </Button>
                </>
              )}
            </form>
          )}
        </Box>
      </Modal>
    </>
  );
};

export default EditCategory;
