import {
  Badge,
  Button,
  IconButton,
  // Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  styled,
  Tooltip,
  Typography,
} from "@material-ui/core";
import {
  ArrowDropDown,
  Person,
  PersonAdd,
  ShoppingCart,
} from "@material-ui/icons";
import { AdminPanelSettings, Login } from "@mui/icons-material";
import React, { useState } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import Helpers from "../Helpers/Helpers";
import { connect } from "react-redux";

const TopRightIcons = (props) => {
  const navigate = useNavigate();
  const { classes, handleDrawerToggle, sx } = props;

  const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
      right: -3,
      top: 0,
      border: `0px solid ${theme.palette.background.paper}`,
      padding: "0 4px",
      background: "#feefdd",
      color: theme.palette.primary.main,
      overlap: "rectangular", // Updated value
    },
  }));

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const userInfo = Helpers.getUser();
  return (
    <>
      <div className={classes.iconsWrapper}>
        <Button className={classes.rightSideIcons} onClick={handleClick}>
          <Typography variant="caption" className={classes.myAccount}>
            My Account
          </Typography>
          <ArrowDropDown />
        </Button>

        <Tooltip title="Shopping Cart">
          <IconButton className={classes.rightSideIcons} onClick={() => navigate("/cart")}>
            <StyledBadge overlap="rectangular" badgeContent={props.cart.cartCount} color="secondary">
              <ShoppingCart />
            </StyledBadge>
          </IconButton>
        </Tooltip>

        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          className={`${classes.rightSideIcons} ${classes.menuIcon}`}
          // sx={{ mr: 10, display: { sm: "none" } }}
          sx={{ mr: 10, display: { lg: "none" } }}
        >
          <MenuIcon />
        </IconButton>
      </div>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        // transformOrigin={{ horizontal: "right", vertical: "top" }}
        // anchorOrigin={{ horizontal: "right", vertical: "bottom" }}

        getContentAnchorEl={null}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        classes={{ paper: classes.dDownMenu }}
        elevation={2}
      >
        {/* check if logged in */}
        {userInfo ? (
          // if logged in
          <List className={classes.dropDownList}>
            <ListItem button component={Link} to="/account">
              <ListItemIcon>
                <PersonAdd fontSize="small" />
              </ListItemIcon>
              <ListItemText className={classes.dropDownText}>
                <span className={classes.dropDownText}>Profile</span>
              </ListItemText>
            </ListItem>

            {/* check if logged in */}
            {userInfo.role == "admin" && (
              <ListItem button component={Link} to="/admin">
                <ListItemIcon>
                  <AdminPanelSettings fontSize="small" />
                </ListItemIcon>
                <ListItemText className={classes.dropDownText}>
                  <span className={classes.dropDownText}>Admin Panel</span>
                </ListItemText>
              </ListItem>
            )}

            <ListItem button component={Link} to="/logout">
              <ListItemIcon>
                <Login fontSize="small" />
              </ListItemIcon>
              <ListItemText>
                <span className={classes.dropDownText}>Log out</span>
              </ListItemText>
            </ListItem>
          </List>
        ) : (
          // if logged out
          <List className={classes.dropDownList}>
            <ListItem button component={Link} to="/register">
              <ListItemIcon>
                <PersonAdd fontSize="small" />
              </ListItemIcon>
              <ListItemText className={classes.dropDownText}>
                <span className={classes.dropDownText}>Register</span>
              </ListItemText>
            </ListItem>
            {/* <Divider /> */}

            <ListItem button component={Link} to="/login">
              <ListItemIcon>
                <Login fontSize="small" />
              </ListItemIcon>
              <ListItemText>
                <span className={classes.dropDownText}>Login</span>
              </ListItemText>
            </ListItem>
          </List>
        )}
      </Menu>
    </>
  );
};

// map state to props
const mapStateToProps = (state) => {
  return {
    cart: state.cart,
  };
};


export default connect(mapStateToProps)(TopRightIcons);
