import React, { useState } from "react";
import EditNoteIcon from "@mui/icons-material/EditNote";
import { Box, Button, CircularProgress, Grid, Modal } from "@material-ui/core";
import modalStyle from "../../../Styles/ModalStyles";
import CustomTextField from "../../../Components/GibsonsComponents/CustomTextField";
import Swal from "sweetalert2";
import axios from "axios";
import Constants from "../../../Components/Helpers/Constants";

const ShippingZoneTitle = (props) => {
  const [shippingZoneTitle, setShippingZoneTitle] = useState(
    props.shippingzone.title
  );
  const [submitting, setSubmitting] = useState(false);
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const submitEditTitleForm = (e) => {
    e.preventDefault();
    setSubmitting(true);

    // API call goes here
    const fd = new FormData();
    fd.append("shippingZoneId", props.shippingzone.id);
    fd.append("shippingZoneTitle", shippingZoneTitle);

    axios
      .post(`${Constants.api}/api/admin/edit-shipping-zone-title`, fd, {
        headers: {
          Accept: "application/json",
        },
      })
      .then((r) => {
        props.getShippingZone();
        setSubmitting(false);
        handleClose();
        Swal.fire({
          title: "Success",
          text: "Shipping zone title was successfully updated.",
          icon: "success",
          confirmButtonText: "Ok",
        });
      })
      .catch((error) => {
        setSubmitting(false);
        console.log(error);
        handleClose();
        Swal.fire({
          title: "Error",
          text: "There was an error: "+error,
          icon: "error",
          confirmButtonText: "Ok",
        });
      });
  };

  return (
    <>
      <Button
        variant="contained"
        endIcon={<EditNoteIcon />}
        size="small"
        onClick={handleClickOpen}
      >
        {props.shippingzone.title}
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        style={{
          position: "absolute",
          top: "10%",
          left: "10%",
          overflow: "scroll",
          height: "100%",
          display: "block",
        }}
      >
        <Box sx={modalStyle}>
          <h3>Edit Shipping Zone Title</h3>
          <form onSubmit={submitEditTitleForm}>
            <Grid item xs={12}>
              <Box my={4}>
                <CustomTextField
                  name="shippingzone_title"
                  label={`Shipping Zone Title`}
                  variant="outlined"
                  size="medium"
                  fullWidth
                  margin="none"
                  type="text"
                  required
                  onChange={(e) => setShippingZoneTitle(e.target.value)}
                  value={shippingZoneTitle}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Button
                type="submit"
                size="small"
                color="primary"
                variant="contained"
                fullWidth
              >
                {submitting ? (
                  <CircularProgress color="secondary" />
                ) : (
                  <>Submit</>
                )}
              </Button>
            </Grid>
          </form>
        </Box>
      </Modal>
    </>
  );
};

export default ShippingZoneTitle;
