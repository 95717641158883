import {
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import axios from "axios";

import React, { useEffect, useState } from "react";
import Constants from "../../../Components/Helpers/Constants";
import DeleteCategory from "./DeleteCategory";
import EditCategory from "./EditCategory";

const ViewCategories = () => {
  const [loadingCategories, setLoadingCategories] = useState(true);
  const [categories, setCategories] = useState(null);

  function getCategories() {
    // axios
    axios
      .get(`${Constants.api}/api/admin/categories`, {
        Accept: "application/json",
      })
      .then((r) => {
        setCategories(r.data[0]);
        setLoadingCategories(false);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  useEffect(() => {
    getCategories();
  }, []);

  if (loadingCategories) {
    return (
      <>
        <Skeleton animation="wave" />
        <Skeleton animation="wave" />
        <Skeleton animation="wave" />
      </>
    );
  } else {
    return (
      <div>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 350 }}>
            <TableHead>
              <TableRow sx={{ textTransform: "uppercase" }}>
                <TableCell>#</TableCell>
                <TableCell>Title</TableCell>
                <TableCell align="right"></TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {categories.map((category, i) => (
                <TableRow key={category.id}>
                  <TableCell>{++i}.</TableCell>
                  <TableCell>{category.title}</TableCell>
                  <TableCell align="right">
                    <EditCategory category={category} getCategories={getCategories} />
                  </TableCell>
                  <TableCell align="right">
                    <DeleteCategory category={category} getCategories={getCategories} />
                  </TableCell>
                </TableRow>
              ))}

            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  }
};

export default ViewCategories;
