import React, { useEffect } from 'react'
import Helpers from '../Helpers/Helpers';
import { useNavigate } from "react-router-dom";

const ProtectedRoutesWhileLoggedOut = ({component, returnTo}) => {
  const navigate = useNavigate();

  useEffect(() => {
    // if returnTo is not undefined, set localstorage to returnto
    if (returnTo !== undefined && !Helpers.isLoggedIn()) {
      Helpers.setLocalStorage('returnTo', returnTo);
    }
    if (!Helpers.isLoggedIn()) navigate("/login");
  }, []);
  
  return (
    <div>
      {component}
    </div>
  )
}

export default ProtectedRoutesWhileLoggedOut
