import { makeStyles } from "@material-ui/core";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => {
  return {
    buttonTypeLink: {
      background: theme.palette.primary.main,
      borderRadius: "2px",
      color: "#FFF",
      fontWeight: "bold",
      padding: theme.spacing(1),
      display: "block",
      width: "100%",
      textAlign: "center",
      textDecoration: "none",
      "&:hover": {
        background: theme.palette.primary.dark,
      },
    },
    logoWrapper: {
      flex: 1,
    },
    logo: {
      padding: "16px 0",
      maxWidth: "148px",
      [theme.breakpoints.down("md")]: {
        maxWidth: "100px",
      },
      [theme.breakpoints.down("sm")]: {
        maxWidth: "80px",
      },
    },
    active: {
      background: "#F4F4F4",
    },
    linksWrapper: {
      flex: 2,
      display: "flex",
      justifyContent: "center",
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
    },
    iconsWrapper: {
      flex: 1,
      display: "flex",
      justifyContent: "flex-end",
    },
    navLinks: {
      color: "#FFFFFF",
      textDecoration: "none",
      fontWeight: 600,
      marginRight: 4,
      paddingLeft: 8,
      paddingRight: 8,
      textTransform: "uppercase",
      fontSize: "15px",
    },
    activeNavLink: {
      color: theme.palette.secondary.main,
    },
    avatar: {
      background: theme.palette.secondary.main,
    },
    changeLocation: {
      display: "flex",
      width: "100%",
      background: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText,
      padding: theme.spacing(2),
    },
    getApp: {
      background: theme.palette.primary.main,
      padding: theme.spacing(1),
      color: "#ffffff",
      width: "100%",
      borderBottom: "1px solid rgba(255,255,255,.5)",
    },
    getAppText: {
      fontWeight: "bold",
      fontSize: "14px",
      textTransform: "uppercase",
      color: "#ffffff",
    },
    downloadIcon: {
      color: theme.palette.primary.main,
      background: "#FFF2E3",
      padding: "2px",
      marginLeft: theme.spacing(1),
      borderRadius: "2px",
    },
    rightSideIcons: {
      color: theme.palette.secondary.main,
    },
    myAccount: {
      fontWeight: "bold",
    },
    dDownMenu: {
      borderLeft: `${theme.spacing(2)} solid`,
      borderColor: theme.palette.primary.main,
    },
    dropDownList: {
      minWidth: "150px",
    },
    dropDownText: {
      fontWeight: "bold",
      fontSize: "12px",
      textTransform: "uppercase",
      color: "rgb(117 117 117)",
    },
    menuIcon: {
      marginLeft: theme.spacing(1),
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
    },
    paper: {
      background: theme.palette.primary.main,
      width: "50%",
      [theme.breakpoints.down("sm")]: {
        width: "60%",
      },
    },
    // admin styles
    adminNavLink: {
      // background: `#F5F5F5 !important`,
    },
    activeAdminNavLink: {
      background: `${theme.palette.primary.main} !important`,
      color: `${theme.palette.primary.contrastText} !important`,
    },
    cartProductLink: {
      color: theme.palette.text.primary,
      fontWeight: "bold",
      textDecoration: "none",
      "&:hover": {
        color: theme.palette.primary.main,
      },
    },
    boldLink: {
      color: theme.palette.primary.main,
      fontWeight: "bold",
      textDecoration: "none",
      "&:hover": {
        color: theme.palette.secondary.main,
      },
    },
    normalLink: {
      color: theme.palette.primary.main,
      "&:hover": {
        color: theme.palette.secondary.main,
      },
    },
  };
});

export default useStyles;
