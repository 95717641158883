import React, { useEffect, useState } from "react";

import {
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import Constants from "../../../Components/Helpers/Constants";
import axios from "axios";
import { Link } from "react-router-dom";
import Helpers from "../../../Components/Helpers/Helpers";

const ShippingzonesList = () => {
  const classes = Helpers.useStyles();
  
  const [loading, setLoading] = useState(true);
  const [shippingzones, setShippingzones] = useState([]);

  const getShipingzones = () => {
    axios
      .get(`${Constants.api}/api/admin/shipping-zones`, {
        Accept: "application/json",
      })
      .then((r) => {
        setShippingzones(r.data);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    getShipingzones();
  }, []);

  if (loading) {
    return (
      <>
        <Skeleton animation="wave" />
        <Skeleton animation="wave" />
        <Skeleton animation="wave" />
      </>
    );
  }
  return (
    <>
      <Box>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 350 }}>
            <TableHead>
              <TableRow sx={{ textTransform: "uppercase" }}>
                <TableCell>#</TableCell>
                <TableCell align="right">Region</TableCell>
                <TableCell align="right">Title</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {shippingzones.map((shippingzone, i) => (
                <TableRow key={i}>
                  <TableCell>{++i}.</TableCell>
                  <TableCell align="right">
                    {shippingzone.region.toUpperCase()}
                  </TableCell>
                  <TableCell align="right">
                    <Link to={`/admin/shipping-zones/list/${shippingzone.id}`} className={classes.boldLink}>
                      {shippingzone.title}
                    </Link>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};

export default ShippingzonesList;
