var url = "https://api.gibscoffee.co.ke";

if (
  window.location.origin == "http://127.0.0.1:3000" ||
  window.location.origin == "http://localhost:3000"
) {
  url = "http://127.0.0.1:8000";
} else if (window.location.origin == "http://127.0.0.1:3001") {
  url = "http://127.0.0.1:8001";
}

function format(num) {
  return new Intl.NumberFormat().format(num);
}

function currency(cur, amount) {
  amount = roundToTwoDP(amount);
  if (cur == "usd") {
    return "$" + this.formatAmount(amount);
  } else {
    return "KSh " + this.formatAmount(amount);
  }
}

// round to 2 decimal places
function roundToTwoDP(num) {
  return +(Math.round(num + "e+2") + "e-2");
}

function getDurationBetweenDates(startDate, endDate) {
  var diff = endDate.getTime() - startDate.getTime();
  var days = Math.floor(diff / (1000 * 60 * 60 * 24));
  var hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  var minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
  var seconds = Math.floor((diff % (1000 * 60)) / 1000);
  return {
    days: days,
    hours: hours,
    minutes: minutes,
    seconds: seconds,
  };
}

function formatAmount(amount, currency) {
  return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export default {
  api: url,
  format,
  currency,
  getDurationBetweenDates,
  formatAmount,
};
