import React from "react";
import {
  Box,
  Divider,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Card,
} from "@material-ui/core";
import Constants from "../Helpers/Constants";
const OrderWrapper = (props) => {
  const { order } = props;

  const currency = order.order_items[0].product.currency;

  return (
    <>
      <Box py={1}>
        <Typography align="center" variant="h5">
          View Order
        </Typography>
      </Box>
      <Divider />
      <Box py={1}>
        <Typography align="center" variant="h6">
          Order ID: {order.id}
        </Typography>
        <Typography align="center" variant="body1">
          Order Date: {order.created_at}
        </Typography>
        <Typography align="center" variant="body1">
          Shipping: {Constants.currency(currency, order.shippingTotal)}
        </Typography>
        <Typography align="center" variant="body1">
          Total:{" "}
          {Constants.currency(currency, order.total + order.shippingTotal)}
        </Typography>
      </Box>
      <Box py={1}>
        <Card elevation={2}>
          <TableContainer>
            <Table sx={{ minWidth: 650 }}>
              <TableHead>
                <TableRow sx={{ textTransform: "uppercase" }}>
                  <TableCell>Product</TableCell>
                  <TableCell align="right">Quantity</TableCell>
                  <TableCell align="right">Price</TableCell>
                  <TableCell align="right">Total</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {order.order_items.map((item) => {
                  return (
                    <TableRow key={item.id}>
                      <TableCell>
                        <Typography variant="body2">
                          {item.product.title}
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Typography variant="body2">{item.quantity}</Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Typography variant="body2">
                          {Constants.currency(currency, item.price)}
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Typography variant="body2">
                          {Constants.currency(
                            currency,
                            item.price * item.quantity
                          )}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </Box>
    </>
  );
};

export default OrderWrapper;
