import React, { useState, useEffect } from "react";
import axios from "axios";
import Constants from "../../../Components/Helpers/Constants";
import { useParams } from "react-router-dom";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Link } from "react-router-dom";

import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
} from "@material-ui/core";
import CountyList from "./kenya-counties.json";
import { Countries } from "./Countries";

import Swal from "sweetalert2";

const ShippingzoneCountriesEdit = () => {
  const { id } = useParams();
  const [shippingZone, setShippingZone] = useState("");
  const [region, setRegion] = useState("kenya");
  const [submitting, setSubmitting] = useState(false);
  const [availablePlaces, setAvailablePlaces] = useState([]);
  const handleChange = (e) => {
    const { name, checked } = e.target;
    // console.log(name, checked);
    if (name === "selectAll") {
      let tempPlace = availablePlaces.map((availablePlace) => {
        return { ...availablePlace, isChecked: checked };
      });
      setAvailablePlaces(tempPlace);
    } else {
      let tempPlace = availablePlaces.map((availablePlace) => {
        if (availablePlace.name === name) {
          return { ...availablePlace, isChecked: checked };
        } else {
          return availablePlace;
        }
      });
      setAvailablePlaces(tempPlace);
    }
  };

  const fetchShippingZoneDetails = async () => {
    try {
      const response = await axios.get(
        `${Constants.api}/api/admin/shipping-zones/list/${id}/edit`,
        {
          headers: {
            Accept: "application/json",
          },
        }
      );

      // Process the shipping zone data as needed
      setShippingZone(response.data.shippingZone);
      setRegion(response.data.shippingZone.region);
      var places = [];
      if(response.data.shippingZone.region == "kenya") {
        const countiesInSelectedZone = await response.data.countiesInSelectedZone;
        places = mergePlacesArrays(CountyList, countiesInSelectedZone);
      } else {
        const countiesInSelectedZone = await response.data.countiesInSelectedZone;
        places = mergePlacesArrays(Countries, countiesInSelectedZone);
      }

      setAvailablePlaces(places);

      // console.log(JSON.stringify(countiesInSelectedZone));
    } catch (error) {
      console.error(error);
    }
  };

  const mergePlacesArrays = (CountyList, countiesInSelectedZone) => {
    const newArray = CountyList.map((item) => {
      item.isChecked = false;
      const matchingItem = countiesInSelectedZone.find(
        (i) => i.county_or_country === item.name
      );
      if (matchingItem) {
        item.isChecked = true;
      }
      return item; // Return the modified item
    });

    const sortedArray = newArray.sort((a, b) => {
      if (a.isChecked && !b.isChecked) {
        return -1; // a should come before b
      } else if (!a.isChecked && b.isChecked) {
        return 1; // b should come before a
      } else {
        return 0; // maintain the original order
      }
    });

    return sortedArray;
  };

  useEffect(() => {
    fetchShippingZoneDetails();
  }, []);

  const updateShippingZone = (e) => {
    e.preventDefault();
    setSubmitting(true);

    let fd = new FormData();
    fd.append("shippingZoneId", shippingZone.id);
    fd.append("destinations", JSON.stringify(availablePlaces));

    axios
      .post(`${Constants.api}/api/admin/shipping-zones/list/${id}/edit`, fd, {
        headers: {
          Accept: "application/json",
        },
      })
      .then((r) => {
        // console.log(r.data);
        // do something here...
        setSubmitting(false);
        Swal.fire({
          title: 'Success',
          text: 'Successfully updated destinations in this zone',
          icon: 'success',
          confirmButtonText: 'Ok',
        });
      })
      .catch((error) => {
        console.log(error);
        setSubmitting(false);
        Swal.fire({
          title: 'Error',
          text: 'There was an error: '+error,
          icon: 'error',
          confirmButtonText: 'Ok',
        });
      });
  };

  return (
    <>
      <Box py={1}>
        <Box pb={2} display="flex" justifyContent="space-between">
          <Typography variant="h4" style={{ textTransform: "uppercase" }}>
            REGION: {region}
          </Typography>
          <Button
            variant="outlined"
            color="primary"
            component={Link}
            to={"/admin/shipping-zones/list/"+id}
            startIcon={<ArrowBackIcon />}
          >
            Back
          </Button>
        </Box>
        <Divider />
        <Box mt={2}>
          <Typography align="left" variant="h5">
            Shipping Zone: {shippingZone.title}
          </Typography>
        </Box>
      </Box>
      <Divider />

      <Box>
        <form onSubmit={updateShippingZone}>
          <Grid container>
            {/* select region first */}
            <Grid item xs={12}>
              <Box mb={2}>
                {region === "kenya" ? (
                  // <CountySelector handleSelect={handleSelect} />
                  <>
                    <Typography variant="h6">
                      Select Destinations In Kenya
                    </Typography>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="selectAll"
                            onChange={handleChange}
                            checked={
                              availablePlaces.filter(
                                (place) => place?.isChecked !== true
                              ).length < 1
                            }
                          />
                        }
                        label="Select All"
                      />
                    </FormGroup>

                    {availablePlaces.map((place, i) => (
                      <div key={i}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name={place.name}
                              onChange={handleChange}
                              checked={place?.isChecked || false}
                            />
                          }
                          label={place.name}
                        />
                      </div>
                    ))}
                  </>
                ) : (

                  
                  <>
                    <Typography variant="h6">
                      Select Destinations In The Rest Of The World
                    </Typography>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="selectAll"
                            onChange={handleChange}
                            checked={
                              availablePlaces.filter(
                                (place) => place?.isChecked !== true
                              ).length < 1
                            }
                          />
                        }
                        label="Select All"
                      />
                    </FormGroup>

                    {availablePlaces.map((place, i) => (
                      <div key={i}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name={place.name}
                              onChange={handleChange}
                              checked={place?.isChecked || false}
                            />
                          }
                          label={place.name}
                        />
                      </div>
                    ))}
                  </>
                

                )}
              </Box>

              {!submitting ? (
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                >
                  Update
                </Button>
              ) : (
                <Button variant="contained" color="primary" fullWidth>
                  <CircularProgress size={24} color="secondary" />
                </Button>
              )}
            </Grid>
          </Grid>
        </form>
      </Box>
    </>
  );
};

export default ShippingzoneCountriesEdit;
