import { makeStyles } from "@material-ui/core";

const allStyles = makeStyles((theme) => {
  return {
    links: {
      color: theme.palette.text.secondary,
      fontWeight: "bold",
      "&:hover": {
        color: theme.palette.primary.main,
      },
    },
    productWrapper: {
      "&:hover": {
        backgroundColor: "#fff5e0 !important",
      },
    },

    productTitle: {
      textTransform: "uppercase",
    },
    price: {
      fontWeight: "bold",
      color: theme.palette.secondary.main,
    },
    productImage: {
      maxWidth: "100%",
      maxHeight: "400px",
      // background: theme.palette.secondary.main,
      // padding: 10,
      borderRadius: 4,
    },
    productTitle2: {
      textTransform: "capitalize",
    },
    productIcons: {
      height: 60,
      width: 60,
      cursor: "pointer",
      borderRadius: "50%",
      backgroundSize: "contain",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundColor: "#EEE",
      border: "8px solid #EEE",
      transition: "all ease-out .3s",
      opacity: 0.7,
      "&:hover": {
        backgroundColor: "#ffd2a0",
        border: "8px solid #FFEFEF",
        opacity: 1,
        transition: "all ease-out .3s",
      },
    },
    categoriesLinks: {
      color: theme.palette.text.secondary,
      textDecoration: "none",
      borderBottom: "1px solid #dedede !important",
      fontWeight: "bold",
      textTransform: "uppercase",
      margin: "0px",
      "&:hover": {
        color: theme.palette.primary.main,
      },
    },
    categoriesLinksAnchor: {
      color: theme.palette.text.secondary,
      display: "block",
      textDecoration: "none",
      paddingTop: "10px",
      margin: "0px",

      paddingBottom: "10px",
      fontWeight: "bold",
      textTransform: "uppercase",
      "&:hover": {
        color: theme.palette.primary.main,
      },
    },
  };
});

export default allStyles;
