import {
  Container,
  Typography,
  Divider,
  Grid,
  Button,
  IconButton,
} from "@material-ui/core";
import { Box } from "@mui/system";
import React from "react";
import Constants from "../../Components/Helpers/Constants";
import { Link } from "react-router-dom";

// import REDUX
import { connect } from "react-redux";
import { Add, Delete, Image, ChevronLeft } from "@material-ui/icons";
import { Remove } from "@mui/icons-material";
import { bindActionCreators } from "redux";
import {
  addToCart,
  reduceFromCart,
  deleteProduct,
  emptyCart,
} from "../../Redux/Actions";
import Swal from "sweetalert2";
import Helpers from "../../Components/Helpers/Helpers";

const Cart = (props) => {

  const classes = Helpers.useStyles();

  const emptyCart = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "This will remove all the items from your cart",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#f8931d",
      cancelButtonColor: "#722623",
      confirmButtonText: "Yes, clear it!",
      reverseButtons: true,
      dangerMode: true,
    }).then((result) => {
      if (result.value) {
        props.emptyCart();
      }
    });
  };

  const deductQtyFromCart = (product) => {
    const productInCart = props.cart.items.find(
      (item) => item.id === product.id
    );

    if (
      productInCart.quantity <= productInCart.min_qty &&
      productInCart.min_qty > 1
    ) {
      Swal.fire({
        title: "Are you sure?",
        text:
          "This product has a minimum order quantity of " +
          productInCart.min_qty +
          ". This will remove this item from your cart.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#F8931D",
        cancelButtonColor: "#722623",
        confirmButtonText: "Yes, remove it!",
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          props.reduceFromCart(product);
        }
      });
    } else {
      // else reduce productInCart.qty by 1
      props.reduceFromCart(product);
    }

    // props.reduceFromCart(product)
  };

  const confirmRemoveProduct = (product) => {
    // use Swal to confirm if you want to remove product from cart
    Swal.fire({
      title: "Are you sure?",
      text: "This will remove this item from your cart.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#F8931D",
      cancelButtonColor: "#722623",
      confirmButtonText: "Yes, remove it!",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        props.deleteProduct(product);
      }
    });
  };

  return (
    <Container>
      <Box my={2}>
        <Button
          component={Link}
          size="small"
          to="/shop"
          variant="outlined"
          color="primary"
        >
          <ChevronLeft /> Back To Shop
        </Button>
      </Box>
      <Box my={2}>
        <Divider />
      </Box>

      <Box my={2}>
        <Typography variant="h4" align="center">
          Shopping Cart
        </Typography>
      </Box>
      <Box my={2}>
        <Divider />
      </Box>

      {props.cart.items.length <= 0 ? (
        <Grid item xs={12} align="center">
          <Typography variant="h5">Your cart is empty.</Typography>
          <Box py={2}>
            <Button
              variant="contained"
              color="primary"
              to="/shop"
              component={Link}
            >
              Go to Shop
            </Button>
          </Box>
        </Grid>
      ) : (
        <>
          <Box my={2}>
            <Grid container>
              <Grid item xs={7}>
                <Typography variant="subtitle1">
                  <b>Product</b>
                </Typography>
              </Grid>
              <Grid item xs={3} align="center">
                <Typography variant="subtitle1">
                  <b>Qty</b>
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="subtitle1" align="right">
                  <b>Subtotal</b>
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Grid container>
            {props.cart.items.map((product) => (
              <Grid item xs={12} key={product.id}>
                <Box
                  py={1}
                  sx={{
                    borderTop: "1px solid #E0E0E0",
                    "&:hover": { background: "#fff8ef" },
                  }}
                  variant=""
                >
                  <Grid container alignItems="center">
                    <Grid item xs={2} align="center">
                      {product.images.length == 0 ? (
                        <Box px={2}>
                          <Image />
                        </Box>
                      ) : (
                        <img
                          src={Constants.api + product.images[0].file_name}
                          height="50"
                        />
                      )}
                    </Grid>
                    <Grid item xs={5}>
                      <Box my={1}>
                        <Typography variant="body1">
                          <Link to={`/shop/product/${product.id}`} className={classes.cartProductLink}>
                            {product.title}
                          </Link>
                        </Typography>
                      </Box>
                      <Box my={1}>
                        <Typography variant="body1">
                          {Constants.currency(product.currency, product.price)}
                        </Typography>
                      </Box>
                      <Box my={1}>
                        <Typography variant="caption">
                          (Min: {product.min_qty})
                        </Typography>
                      </Box>
                      <Box my={1}>
                        <Button
                          size="small"
                          style={{ fontSize: "0.55rem" }}
                          variant="contained"
                          disableElevation
                          onClick={() => confirmRemoveProduct(product)}
                        >
                          Remove
                        </Button>
                      </Box>
                    </Grid>
                    <Grid item xs={3} align="center">
                      <Box p={1}>
                        <IconButton
                          size="small"
                          color="secondary"
                          onClick={() => props.addToCart(product, 1)}
                        >
                          <Add fontSize="small" />
                        </IconButton>
                      </Box>
                      <Box p={1}>{product.quantity}</Box>
                      <Box p={1}>
                        <IconButton
                          size="small"
                          color="secondary"
                          onClick={() => deductQtyFromCart(product)}
                        >
                          <Remove fontSize="small" />
                        </IconButton>
                      </Box>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography variant="body1" align="right">
                        {Constants.currency(
                          product.currency,
                          product.quantity * product.price
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            ))}
          </Grid>

          <Box>
            <Divider />
          </Box>

          <Box py={4}>
            <Grid container justifyContent="space-between">
              <Typography align="left">
                <Button
                  variant="contained"
                  disableElevation
                  onClick={() => emptyCart()}
                  size="small"
                  style={{ fontSize: "0.55rem" }}
                  icon={<Delete />}
                >
                  Clear Cart
                </Button>
              </Typography>
              <Typography variant="h6" align="right">
                TOTAL:{" "}
                {Constants.currency(
                  props.cart.items[0].currency,
                  props.cart.cartTotal
                )}
              </Typography>
            </Grid>

            <Box my={2}>
              <Grid container justifyContent="flex-end">
                <Typography variant="caption">
                  Shipping calculated at checkout.{" "}
                  {/* <Link to="/shipping-rates" target="_blank">
                    See rates
                  </Link> */} 
                  {/* TODO: create a page to display shipping rates */}
                </Typography>
              </Grid>
            </Box>

            <Grid container justifyContent="flex-end">
              <Button
                component={Link}
                to="/checkout"
                variant="contained"
                disableElevation
                color="primary"
                size="large"
              >
                Check out
              </Button>
            </Grid>
          </Box>
        </>
      )}
    </Container>
  );
};

// connect props
const mapStateToProps = (state) => {
  return {
    cart: state.cart,
  };
};

// mapDispatchToProps
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { addToCart, reduceFromCart, deleteProduct, emptyCart },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Cart);
