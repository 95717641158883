import React from "react";
import { Typography, Container, Grid } from "@material-ui/core";
import { Box } from "@mui/system";
import Splash from "./Splash";
import EthicalProduction from "./EthicalProduction";
import History from "./History";

const About = (props) => {
  return (
    <>
      <Splash />
      <EthicalProduction />
      <History />
    </>
  );
};

export default About;
