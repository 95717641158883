import { makeStyles } from "@material-ui/styles";
import {
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Constants from "../../../Components/Helpers/Constants";
import Image from '@material-ui/icons/Image';

const AdminViewProducts = () => {
  const useStyles = makeStyles({
    textLink: {
      color: "#F8931D",
      textDecoration: "none",
      "&:hover": { textDecoration: "underline" },
    },
    icon: {
      color: "rgba(120,120,120,1)",
      fontSize: 28
    }
  });

  const classes = useStyles();

  const [products, setProducts] = useState(null);
  const [loadingProducts, setLoadingProducts] = useState(true);

  const getProducts = () => {
    axios
      .get(`${Constants.api}/api/admin/products`, {
        Accept: "application/json",
      })
      .then((r) => {
        setProducts(r.data[0]);
        setLoadingProducts(false);
      })
      .catch((e) => {
        console.log(e.response.data);
        setLoadingProducts(false);
      });
  };

  useEffect(() => {
    getProducts();
  }, []);

  if (loadingProducts) {
    return (
      <>
        <Skeleton animation="wave" />
        <Skeleton animation="wave" />
        <Skeleton animation="wave" />
      </>
    );
  } else {
    return (
      <div>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow sx={{ textTransform: "uppercase" }}>
                <TableCell></TableCell>
                <TableCell>Title</TableCell>
                <TableCell align="right">Price</TableCell>
                <TableCell align="right">Region</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {products.map((product) => (
                <TableRow key={product.id}>
                  <TableCell>
                    {product.file_name == null ? (
                      <Image className={classes.icon} />
                    ) : (
                      <img
                        src={`${Constants.api}${product.file_name}`}
                        height="50"
                      />
                    )}
                  </TableCell>
                  <TableCell>
                    <Link
                      to={`/admin/product/${product.id}`}
                      className={classes.textLink}
                    >
                      {product.title}
                    </Link>
                  </TableCell>
                  <TableCell align="right">
                    {product.currency == "usd"
                      ? "$"
                      : product.currency == "kshs"
                      ? "kes "
                      : product.currency}
                    {product.price}
                  </TableCell>
                  <TableCell align="right">{product.region}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  }
};

export default AdminViewProducts;
