import React from 'react'
import { Typography, Container, Grid } from "@material-ui/core";
import { Box } from "@mui/system";

const Splash = () => {
  return (
    <>
      <div className="aboutGibsonsVideosWrapper">
        <Container>
          <Box py={2}>
            <Box mb={2}>
              <Typography variant="h4" color="primary" align="center">
                ABOUT GIBSONS COFFEE
              </Typography>
            </Box>
            <Box my={2}>
              <Typography
                variant="h5"
                align="center"
                style={{ color: "#EEEEEE" }}
              >
                HOW OUR COFFEE IS MADE...
              </Typography>
            </Box>
            <Box my={2}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <iframe
                    width="100%"
                    height={300}
                    src="https://www.youtube.com/embed/GM2HsxZYWEE?rel=0"
                    frameBorder={0}
                    allowFullScreen
                    className="iframeBg"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <iframe
                    width="100%"
                    height={300}
                    src="https://www.youtube.com/embed/CMXUCGz1feU?rel=0"
                    frameBorder={0}
                    allowFullScreen
                    className="iframeBg"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <iframe
                    width="100%"
                    height={300}
                    src="https://www.youtube.com/embed/WCotdNx7Poo?rel=0"
                    frameBorder={0}
                    allowFullScreen
                    className="iframeBg"
                  />
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Container>
      </div>
    </>
  )
}

export default Splash
