import axios from "axios";
import { 
  Container, 
  Grid, 
  Box, 
  Paper, 
  Typography,
  Button,
  CircularProgress,
 } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import CustomTextField from "../../Components/GibsonsComponents/CustomTextField";
import Constants from "../../Components/Helpers/Constants";
import useLinkStyles from "../../Styles/linkStyles";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";


const PasswordReset = () => {

  const linkClasses = useLinkStyles();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [formErrors, setFormErrors] = useState(false);

  // Function to validate email
  const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  // Function to make API call
  const resetPassword = async (email) => {
    const fd = new FormData();
    fd.append("email", email);

    try {
      const response = await axios.post(`${Constants.api}/api/reset`, fd, {
        headers: {
          Accept: "application/json",
        },
      });
      console.log(response);
      return response;
    } catch (error) {
      throw error;
    }
  }

  const reset = async (e) => {
    e.preventDefault();
  
    // Validate email before sending request
    if (!validateEmail(email)) {
      alert("Please enter a valid email address.");
      return;
    }
  
    setLoading(true);
    setFormErrors(false);
  
    try {
      await resetPassword(email);
      setEmail("");
      setLoading(false);
      Swal.fire({
        title: "Password Reset Successful",
        text: "A password reset has been initiated for your account. Please check your email inbox for further instructions.",
        icon: "success",
        confirmButtonText: "Ok",
      });
    } catch (error) {
      if (error?.response?.data?.error) {
        // Display specific error message for field errors
        setFormErrors({ email: error.response.data.error });
      } else {
        // Display generic error message for other errors
        Swal.fire({
          title: "Error",
          text: "An error occurred. Please try again.",
          icon: "error",
          confirmButtonText: "Ok",
        });
      }
      console.log(error);
      setLoading(false);
    }
  }

  return (
    <Container>
      <Box sx={{ my: 4 }}>
        <Grid container justifyContent="center">
          <Grid item md={6} sm={12}>
            <Paper elevation={2}>
              <Box sx={{ my: 4, py: 3, px: 3 }}>
                <Typography variant="h4" align="center" color="secondary">
                  Password Reset
                </Typography>
                <Box p={2}>

                  <form onSubmit={reset}>
                    <CustomTextField
                      label="Email"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      onChange={(e) => setEmail(e.target.value)}
                      type="email"
                      helperText={formErrors.email}
                      error={formErrors.email ? true : false}
                      value={email}
                    />

                    <Box sx={{ pt: 2 }}>
                      <Grid container>
                        <Grid item xs={6}>
                          <Box>
                            <Link
                              to="/login"
                              className={linkClasses.links}
                            >
                              Login
                            </Link>
                          </Box>
                          <Box>
                            <Link to="/register" className={linkClasses.links}>
                              Create Account
                            </Link>
                          </Box>
                        </Grid>
                        <Grid item xs={6}>
                          <Box textAlign="right">
                            {loading ? (
                              <CircularProgress size={24} />
                            ) : (
                              <>
                                <Button
                                  variant="contained"
                                  color="primary"
                                  onClick={reset}
                                >
                                  RESET
                                </Button>
                              </>
                            )}
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </form>

                </Box>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}

export default PasswordReset;
