import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Typography,
} from "@material-ui/core";
import { Skeleton } from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import CustomTextField from "../../Components/GibsonsComponents/CustomTextField";
import Constants from "../../Components/Helpers/Constants";

const Account = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [building, setBuilding] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(true);

  // get user from local storage
  const user = JSON.parse(localStorage.getItem("user-info"));

  const getUserInfo = () => {
    console.log("user deets: ");
    console.log(user);
    
    axios
      .get(`${Constants.api}/api/user/details?id=${user.id}`, {
        Accept: "application/json",
      })
      .then((r) => {
        setFirstName(r.data.first_name);
        setLastName(r.data.last_name);
        setEmail(r.data.email);
        setPhone(r.data.phone);
        setBuilding(r.data.building);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    getUserInfo();
  }, []);

  const editAccount = (e) => {
    setSubmitting(true);
    e.preventDefault();
    axios
      .post(
        `${Constants.api}/api/user/edit`,
        {
          id: user.id,
          first_name: firstName,
          last_name: lastName,
          email: email,
          phone: phone,
          building: building,
        },
        {
          Accept: "application/json",
        }
      )
      .then((r) => {
        setSubmitting(false);
        localStorage.setItem("user-info", JSON.stringify(r.data));
      })
      .catch((e) => {
        console.log(e);
      });
  };

  if (loading) {
    return (
      <>
        <Skeleton animation="wave" />
        <Skeleton animation="wave" />
        <Skeleton animation="wave" />
      </>
    );
  }

  return (
    <>
      <Box py={1}>
        <Typography align="center" variant="h5">
          Account
        </Typography>
      </Box>
      <Divider />
      <Box py={1}>
        <form onSubmit={editAccount}>
          <CustomTextField
            label="First Name"
            variant="outlined"
            fullWidth
            margin="normal"
            onChange={(e) => setFirstName(e.target.value)}
            type="text"
            required
            value={firstName}
          />
          <CustomTextField
            label="Last Name"
            variant="outlined"
            fullWidth
            margin="normal"
            onChange={(e) => setLastName(e.target.value)}
            type="text"
            required
            value={lastName}
          />
          <CustomTextField
            label="Email"
            variant="outlined"
            fullWidth
            margin="normal"
            onChange={(e) => setEmail(e.target.value)}
            type="text"
            required
            value={email}
          />
          <CustomTextField
            label="Phone"
            variant="outlined"
            fullWidth
            margin="normal"
            onChange={(e) => setPhone(e.target.value)}
            type="text"
            required
            value={phone}
          />
          <CustomTextField
            label="Address"
            variant="outlined"
            fullWidth
            margin="normal"
            onChange={(e) => setBuilding(e.target.value)}
            type="text"
            required
            value={building}
          />

          <Button
            variant="contained"
            color="primary"
            type="submit"
            size="large"
            fullWidth
            disableElevation
          >
            {submitting ? (
              <CircularProgress color="secondary" />
            ) : (
              <span>SUBMIT</span>
            )}
          </Button>
        </form>
      </Box>
    </>
  );
};

export default Account;
