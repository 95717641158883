import React from "react";
import { Button } from "@material-ui/core";
import Swal from "sweetalert2";
import axios from "axios";
import Constants from "../../../Components/Helpers/Constants";

const DeleteCategory = (props) => {
  function deleteCategory(id) {
    const fd = new FormData();
    fd.append("id", id);

    Swal.fire({
      title: "Are you sure?",
      text: "Confirm if you want to permanently delete!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .post(`${Constants.api}/api/admin/category/delete`, fd, {
            Accept: "application/json",
          })
          .then((r) => {
            props.getCategories();
          }).then(()=> {
            Swal.fire("Deleted!", "Successfully deleted.", "success");
          })
          .catch((e) => {
            console.log(e);
          });
      }
    });
  }

  return (
    <>
      <Button
        onClick={() => deleteCategory(props.category.id)}
        color="primary"
        variant="outlined"
        size="small"
      >
        Delete
      </Button>
    </>
  );
};

export default DeleteCategory;
