import React from "react";
import { Container, Grid, Typography } from "@material-ui/core";
import { Box } from "@mui/system";

const Blog = () => {
  return (
    <div>
      <Container>
        <Box py={4}>
          <Typography variant="h4" gutterBottom color="primary">
            Blog
          </Typography>
          {/* coming soon */}
          <Typography variant="h6" gutterBottom>
            Coming Soon. Please check back later.
          </Typography>
        </Box>
      </Container>
    </div>
  );
};

export default Blog;
