import React, { useState, useEffect } from "react";
import axios from "axios";
import Constants from "../../Components/Helpers/Constants";
import { NavLink } from "react-router-dom";
import { Box, Divider, List, Paper, Typography } from "@material-ui/core";
import allStyles from "../../Styles/allStyles";
import { Skeleton } from "@mui/material";

const CategoriesComponent = (props) => {
  const classes = allStyles();
  const [loadingCategories, setLoadingCategories] = useState(true);
  const [categories, setCategories] = useState(null);

  const getCategories = () => {
    axios
      .get(`${Constants.api}/api/categories`, {
        Accept: "application/json",
      })
      .then((r) => {
        setCategories(r.data[0]);
        setLoadingCategories(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    getCategories();
  }, []);

  if (loadingCategories) {
    return (
      <>
        <Skeleton animation="pulse" />
        <Skeleton animation="pulse" />
        <Skeleton animation="pulse" />
      </>
    );
  }

  return (
    <div>
      <Box p={2} my={2} component={Paper} variant="outlined">
        <List>
          <li className={classes.categoriesLinks}>
            <NavLink
              to={`/shop`}
              className={classes.categoriesLinksAnchor}
              activeClassName="activeCategory"
              // isActive if path is exactly equal to shop
              isActive={(match, location) =>
                location.pathname === "/shop" ? true : false
              }
            >
              ALL PRODUCTS
            </NavLink>
          </li>
          
          {categories.map((category, index) => {
            return (
              <div key={index}>
                <li className={classes.categoriesLinks}>
                  <NavLink
                    to={`/shop/category/${category.id}`}
                    className={({ isActive }) =>
                      isActive
                        ? `${classes.categoriesLinksAnchor} activeCategory`
                        : classes.categoriesLinksAnchor
                    }
                  >
                    {category.title}
                  </NavLink>
                </li>
              </div>
            );
          })}

        </List>
      </Box>
    </div>
  );
};

export default CategoriesComponent;
