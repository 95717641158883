import React, { useEffect, useState } from "react";

import {
  Box,
  Container,
  Paper,
  Typography,
  Grid,
  Button,
  CircularProgress,
} from "@material-ui/core";

import axios from "axios";
import Constants from "../../Components/Helpers/Constants";
import CustomTextField from "../../Components/GibsonsComponents/CustomTextField";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import useLinkStyles from "../../Styles/linkStyles";
import Helpers from "../../Components/Helpers/Helpers";

function Register() {
  const linkClasses = useLinkStyles();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [formErrors, setFormErrors] = useState(false);
  const navigate = useNavigate();


  function signUp() {
    setLoading(true);
    setFormErrors(false);

    const fd = new FormData();
    fd.append("first_name", firstName);
    fd.append("last_name", lastName);
    fd.append("email", email);
    fd.append("password", password);

    axios
      .post(`${Constants.api}/api/register`, fd, {
        headers: {
          Accept: "application/json",
        },
      })
      .then((r) => {
        console.log(r.data[0]);
        localStorage.setItem("user-info", JSON.stringify(r.data[0]));
        setLoading(false);
        // if returnTo localstorage is set, redirect to that page
        if (Helpers.getLocalStorage("returnTo")) {
          navigate(Helpers.getLocalStorage("returnTo"));
        } else {
          navigate("/cart");
        }
      })
      .catch((error) => {
        setLoading(false);
        setFormErrors(error.response.data.error);
      });
  }

  return (
    <>
      <Container>
        <Box sx={{ my: 4 }}>
          <Grid container justifyContent="center">
            <Grid item md={6} sm={12}>
              <Paper elevation={2}>
                <Box sx={{ my: 4, py: 3, px: 3 }}>
                  <Typography variant="h4" align="center" color="secondary">
                    Create Account
                  </Typography>
                  <Box p={2}>
                    <form>
                      <CustomTextField
                        label="First Name"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        onChange={(e) => setFirstName(e.target.value)}
                        helperText={formErrors.first_name}
                        error={formErrors.first_name ? true : false}
                      />
                      <CustomTextField
                        label="Last Name"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        onChange={(e) => setLastName(e.target.value)}
                        helperText={formErrors.last_name}
                        error={formErrors.last_name ? true : false}
                      />
                      <CustomTextField
                        label="Email"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        type="email"
                        onChange={(e) => setEmail(e.target.value)}
                        helperText={formErrors.email}
                        error={formErrors.email ? true : false}
                      />
                      <CustomTextField
                        label="Password"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        type="password"
                        onChange={(e) => setPassword(e.target.value)}
                        helperText={formErrors.password}
                        error={formErrors.password ? true : false}
                      />
                      <Box sx={{ pt: 2 }}>
                        <Grid container>
                          <Grid item xs={6}>
                            <Box>
                              <Link
                                to="/password-reset"
                                className={linkClasses.links}
                              >
                                Forgot password?
                              </Link>
                            </Box>
                            <Box>
                              <Link to="/login" className={linkClasses.links}>
                                Log In
                              </Link>
                            </Box>
                          </Grid>
                          <Grid item xs={6}>
                            <Box textAlign="right">
                              {loading ? (
                                <CircularProgress size={24} />
                              ) : (
                                <>
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={signUp}
                                  >
                                    CREATE
                                  </Button>
                                </>
                              )}
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    </form>
                  </Box>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
}

export default Register;
