import { Box, Img } from "@material-ui/core";
import React, { useState } from "react";
import ModalVideo from "react-modal-video";
import "react-modal-video/scss/modal-video.scss";

function VideoComponent(props) {
  const [isOpen, setOpen] = useState(false);

  return (
    <div className="video-preview">
      <ModalVideo
        channel="youtube"
        isOpen={isOpen}
        videoId={props.videoId}
        onClose={() => setOpen(false)}
        youtube={{ autoplay: 1 }}
        // controls={0} // Hide player controls
        modestbranding={1} // Hide YouTube logo
        // fs={0} // Hide fullscreen button
      />
      <Box sx={{ p: 2 }}>
        <Box
          component="img"
          src={props.imageUrl}
          alt="Click to play video"
          onClick={() => setOpen(true)}
          sx={{
            cursor: "pointer",
            "&:hover": {
              transform: "scale(1.05)", // Increase the size on hover
              transition: "transform 0.3s ease", // Smooth transition
            },
            // width: "100%", // Make sure the image takes the full width of its container
            "@media (max-width: 1363px)": {
              // Exclude 'lg' screens (1280px and above)
              maxWidth: "100%", // Apply maxWidth for screens smaller than 'lg'
            },
          }}
        />
      </Box>
    </div>
  );
}

export default VideoComponent;
