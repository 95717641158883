import {
  Card,
  Container,
  Grid,
  List,
  ListItem,
  Typography,
  ListItemText,
  Divider,
} from "@material-ui/core";
import { ListItemButton } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect } from "react";

import { useNavigate, useLocation } from "react-router-dom";
import { NavLink } from "react-router-dom";
import Helpers from "../Helpers/Helpers";
import useStyles from "./LayoutCSS";

const AdminRoutes = ({ component }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const classes = useStyles();

  useEffect(() => {
    if (!Helpers.isLoggedIn()) navigate("/login");
    if (Helpers.getUser().role != "admin") navigate("/cart");
  }, []);

  return (
    <Container>
      <Box py={2}>
        <Grid container>
          <Grid item xs={12} sm={4}>
            <Box px={2} mb={2}>
              <Card elevation={2}>
                <Box py={4}>
                  <Typography variant="h5" align="center">
                    Administrator
                  </Typography>
                </Box>
                <Divider />
                <List disablePadding>
                  {adminLinks.map((item, i) => (
                    <ListItem disableGutters key={i}>
                      <ListItemButton
                        component={NavLink}
                        to={item.url}
                        className={
                          location.pathname == item.url
                            ? `${classes.activeAdminNavLink}`
                            : classes.adminNavLink
                        }
                      >
                        <ListItemText primary={item.text} />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              </Card>
            </Box>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Box px={2}>{component}</Box>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

const adminLinks = [
  {
    text: "View Products",
    url: "/admin",
  },
  {
    text: "Add Product",
    url: "/admin/add-product",
  },
  {
    text: "View Categories",
    url: "/admin/view-categories",
  },
  {
    text: "Add Category",
    url: "/admin/add-category",
  },
  {
    text: "View Users",
    url: "/admin/users",
  },
  {
    text: "View Orders",
    url: "/admin/orders",
  },
  {
    text: "Create Shipping Zone",
    url: "/admin/shipping-zones/new",
  },
  {
    text: "List Shipping Zones",
    url: "/admin/shipping-zones/list",
  },
];

export default AdminRoutes;
