import {
  Button,
  Container,
  Grid,
  Box,
  Typography,
  Divider,
} from "@material-ui/core";
import { ChevronLeft } from "@mui/icons-material";
import { Skeleton } from "@mui/material";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { bindActionCreators } from "redux";
import Swal from "sweetalert2";
import CustomTextField from "../../Components/GibsonsComponents/CustomTextField";
import ProductGallery from "../../Components/GibsonsComponents/ProductGallery";
import Constants from "../../Components/Helpers/Constants";
import { addToCart } from "../../Redux/Actions";
import allStyles from "../../Styles/allStyles";
import CategoriesComponent from "./CategoriesComponent";

const Product = (props) => {
  const classes = allStyles();

  const [product, setProduct] = useState(null);
  const [loadingProduct, setLoadingProduct] = useState(true);
  const { id } = useParams();
  const [qty, setQty] = useState(1);
  const defaultValue = 1;
  const navigate = useNavigate();

  function addItemToCart(e) {
    let minQtyMessage = "";
    e.preventDefault();
    if (qty < 1) return false;
    setQty(qty);

    // check if product is already in cart
    const cart = props.cart.items;
    const productInCart = cart.find((item) => item.id === product.id);
    if (!productInCart) {
      // check if qty is less than min_qty
      if (qty < product.min_qty) {
        minQtyMessage =
          "This product has a minimum order quantity of " +
          product.min_qty +
          ". ";
      }
    }

    props.addToCart(product, qty);

    Swal.fire({
      title: "Success!",
      text: minQtyMessage + "Item added to cart!",
      icon: "success",
      showCancelButton: true,
      confirmButtonColor: "#f8931d",
      cancelButtonColor: "#722623",
      confirmButtonText: "CONTINUE SHOPPING",
      cancelButtonText: "GO TO CART",
    }).then((result) => {
      if (result.value) {
        // navigate("/shop");
        
      } else {
        navigate("/cart");
      }
    });

    e.target.reset();
    setQty(defaultValue);
  }

  function addAndGoToCart() {
    props.addToCart(product, 1);
    navigate(`/cart`);
  }

  function getProduct() {
    axios
      .get(`${Constants.api}/api/product/${id}`, {
        Accept: "application/json",
      })
      .then((r) => {
        setProduct(r.data[0]);
        setLoadingProduct(false);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  useEffect(() => {
    getProduct();
    setQty(defaultValue);
  }, []);

  // console.log(product);

  if (loadingProduct) {
    return (
      <Container>
        <Skeleton animation="pulse" />
        <Skeleton animation="pulse" />
        <Skeleton animation="pulse" />
      </Container>
    );
  } else {
    if (product == undefined) {
      return (
        <Box p={4} align="center">
          <Typography>Product doesn't exist</Typography>
          <Button
            exact
            component={NavLink}
            size="small"
            to="/shop"
            variant="outlined"
            color="primary"
          >
            Back To Shop
          </Button>
        </Box>
      );
    } else {
      return (
        <Container>
          <Box my={2}>
            <Typography variant="h4" align="center">
              Shop
            </Typography>
          </Box>
          <Box my={2}>
            <Divider />
          </Box>
          <Grid container>
            <Grid item xs={12} md={4}>
              <Box pr={4}>
                <CategoriesComponent />
              </Box>
            </Grid>
            <Grid item xs={12} md={8}>
              <Grid container>
                <Grid item xs={12} md={4}>
                  <Box py={2} align="center">
                    <ProductGallery imgs={product.images} />
                  </Box>
                </Grid>
                <Grid item xs={12} md={8}>
                  <Box p={2}>
                    <Box pb={2}>
                      <Typography
                        variant="h5"
                        color="secondary"
                        className={classes.productTitle2}
                      >
                        {product.title}
                      </Typography>
                    </Box>
                    <Box pb={1}>
                      <Typography variant="h6">
                        {Constants.currency(product.currency, product.price)}
                      </Typography>
                    </Box>
                    {product.min_qty > 0 && (
                      <Box pb={1}>
                        <Typography variant="caption" color="textSecondary">
                          MINIMUM: {product.min_qty}
                        </Typography>
                      </Box>
                    )}
                    <form onSubmit={addItemToCart}>
                      <Box pb={2}>
                        <CustomTextField
                          label="Quantity"
                          variant="outlined"
                          margin="normal"
                          onChange={(e) => setQty(parseInt(e.target.value))}
                          type="number"
                          required
                          // if not in cart and has a minimum qty, use that as default value, otherwise min_qty is 1
                          defaultValue={defaultValue}
                          // placeholder={"Minimum:"+product.min_qty}
                          InputProps={{ inputProps: { min: 1 } }}
                        />
                      </Box>
                      <Box pb={2}>
                        <Box>
                          <Button
                            color="primary"
                            variant="outlined"
                            style={{ width: "320px" }}
                            type="submit"
                          >
                            Add To Cart
                          </Button>
                        </Box>
                      </Box>
                    </form>

                    <Box pb={2}>
                      <Box>
                        <Button
                          disableElevation
                          color="primary"
                          variant="contained"
                          style={{ width: "320px" }}
                          onClick={() => addAndGoToCart()}
                        >
                          Buy It Now
                        </Button>
                      </Box>
                    </Box>

                    <Box pb={2}>
                      <Box dangerouslySetInnerHTML={{ __html: product.description }} />
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      );
    }
  }
};

const mapStateToProps = (state) => {
  return { cart: state.cart };
};

// mapDispatchToProps
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ addToCart }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Product);
