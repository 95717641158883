import { Button, Divider, Grid, Typography } from "@material-ui/core";

import { Box } from "@mui/system";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Constants from "../../../Components/Helpers/Constants";
import DeleteProduct from "./DeleteProduct";
import EditImages from "./EditImages";
import EditProducts from "./EditProducts";

const AdminProductDetails = () => {
  const { productId } = useParams();
  const [product, setProduct] = useState("");
  const [loadingProduct, setLoadingProduct] = useState(true);
  const [categories, setCategories] = useState(null);
  // get product
  const getProduct = () => {
    axios
      .get(`${Constants.api}/api/admin/product/${productId}`, {
        Accept: "application/json",
      })
      .then((r) => {
        // console.log(r.data[0]);
        setProduct(r.data[0]);

        axios
          .get(`${Constants.api}/api/admin/categories`, {
            Accept: "application/json",
          })
          .then((res) => {
            // abc
            setCategories(res.data[0]);
            setLoadingProduct(false);
          });
      })
      .catch((e) => {
        console.log(e.response.data);
      });
  };

  useEffect(() => {
    getProduct();
  }, []);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      {loadingProduct ? (
        <div>loading...</div>
      ) : (
        <div>
          <Box>
            <Box>
              <Typography
                variant="h5"
                align="center"
                style={{ textTransform: "uppercase" }}
              >
                {product.title}
              </Typography>
            </Box>
            <Box my={1}>
              <Divider />
            </Box>

            <Grid container spacing={1} justifyContent="center">
              <Grid item>
                <Button
                  onClick={handleOpen}
                  variant="outlined"
                  color="primary"
                  size="small"
                >
                  Add Images
                </Button>
              </Grid>
              <Grid item>
                <DeleteProduct product={product} />
              </Grid>
            </Grid>

            <Box my={1}>
              <Divider />
            </Box>

            <Box>
              <EditProducts categories={categories} product={product} />
            </Box>

            <Box>
              <EditImages
                product={product}
                open={open}
                handleClose={handleClose}
                setOpen={setOpen}
              />
            </Box>
          </Box>
        </div>
      )}
    </>
  );
};

export default AdminProductDetails;
