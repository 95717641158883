import axios from "axios";
import {
  Container,
  Grid,
  Box,
  Paper,
  Typography,
  Button,
  CircularProgress,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Constants from "../../Components/Helpers/Constants";
import useLinkStyles from "../../Styles/linkStyles";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { useNavigate, useLocation } from "react-router-dom";

const PasswordlessLogin = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("token");

  const navigate = useNavigate();

  const linkClasses = useLinkStyles();
  const [loading, setLoading] = useState(false);

  // Function to make API call
  const resetPassword = async (token) => {
    const fd = new FormData();
    // alert(token);
    fd.append("token", token);

    try {
      const response = await axios
        .post(`${Constants.api}/api/reset-password-token`, fd, {
          headers: {
            Accept: "application/json",
          },
        })
        .then((r) => {
          localStorage.setItem("user-info", JSON.stringify(r.data[0])); // uncomment this
          setLoading(false);
          navigate("/account/password"); // uncomment this
        });
      console.log(response);
      return response;
    } catch (error) {
      throw error;
    }
  };

  const reset = async (e) => {
    setLoading(true);
    try {
      // get the token from url and pass it to resetPassword function
      await resetPassword(token);
      setLoading(false);
      // log in the user
    } catch (error) {
      // Display error message
      Swal.fire({
        title: "Error",
        text: "An error occurred. Please try again by requesting another token.",
        icon: "error",
        confirmButtonText: "Ok",
      });
      // redirect to /password-reset
      navigate("/password-reset"); // uncomment this
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    reset();
  }, []);

  // call reset function as soon as page loads

  return (
    <Container>
      <Box sx={{ my: 4 }}>
        <Grid container justifyContent="center">
          <Grid item sm={6}>
            <Paper elevation={2}>
              <Box sx={{ my: 4, py: 3, px: 3 }}>
                <Typography variant="h4" align="center" color="secondary">
                  Password Reset
                </Typography>
                <Box p={2}>
                  <Box sx={{ pt: 2 }}>
                    <Grid container>
                      <Grid item xs={12}>
                        <Box textAlign="center">
                          {loading && (
                            <Box>
                              <Box>Please hang tight...</Box>
                              <CircularProgress size={24} />
                            </Box>
                          )}
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default PasswordlessLogin;
