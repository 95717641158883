import { Skeleton, Stack } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

const ImagePlaceHolder = (props) => {
  return (
    <>
      <Box>
        <Stack spacing={1} /* alignItems="center" */>
          <Skeleton
            variant="rectangular"
            // width={props.width}
            height={props.hTop}
            animation={false}
          />
          <Skeleton
            variant="circular"
            width={40}
            height={40}
            animation={false}
          />
          <Skeleton
            variant="rectangular"
            // width={props.width}
            height={props.hBottom}
            animation={false}
          />
        </Stack>
      </Box>
    </>
  );
};

export default ImagePlaceHolder;
