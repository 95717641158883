// add to cart action with dispatch
export const addToCart = (product, qty) => {
  return function (dispatch) {
    dispatch({
      type: "ADD_TO_CART",
      payload: {
        product,
        qty,
      },
    });
  };
};

// reduce cart action with dispatch
export const reduceFromCart = (product) => {
  return function (dispatch) {
    dispatch({
      type: "REDUCE_FROM_CART",
      payload: {
        product,
      },
    });
  };
};

// delete product action with dispatch
export const deleteProduct = (product) => {
  return function (dispatch) {
    dispatch({
      type: "DELETE_PRODUCT",
      payload: {
        product,
      },
    });
  }
}

// empty cart action with dispatch
export const emptyCart = () => {
  return function (dispatch) {
    dispatch({
      type: "EMPTY_CART",
    });
  }
}

// set location
export const setLocation = (location) => {
  return function (dispatch) {
    dispatch({
      type: "SET_LOCATION",
      payload: {
        location,
      },
    });
  }
}

