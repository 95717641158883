import {
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import { Box } from "@mui/system";
import axios from "axios";
import React, { useState } from "react";
import CustomSelectField from "../../../Components/GibsonsComponents/CustomSelectField";
import CustomTextField from "../../../Components/GibsonsComponents/CustomTextField";
import Constants from "../../../Components/Helpers/Constants";
import Swal from "sweetalert2";

const AddCategory = () => {
  const [title, setTitle] = useState("");
  const [loading, setLoading] = useState(false);

  const [formErrors, setFormErrors] = useState(false);

  function createCategory(e) {
    e.preventDefault();
    setFormErrors(false);
    const fd = new FormData();
    fd.append("title", title);

    setLoading(true);

    axios
      .post(`${Constants.api}/api/admin/add-category`, fd, {
        headers: {
          Accept: "application/json",
        },
      })
      .then((r) => {
        setLoading(false);
        // console.log(r.data[0]);
        setTitle("");
        e.target.reset();
        Swal.fire("Success", "Category was successfully created!", "success");
      })
      .catch((error) => {
        console.log(error);
        if(typeof error.response.data != undefined) {
          setFormErrors(error.response.data.error);
        }
        setLoading(false);
      });
  }

  return (
    <div>
      <Box py={1}>
        <Typography align="center" variant="h5">
          Add Category
        </Typography>
      </Box>
      <Divider />
      <Box py={1}>
        <form onSubmit={createCategory}>
          <Grid container>
            <Grid item xs={12}>
              <Box px={1}>
                <CustomTextField
                  label="Title"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  onChange={(e) => setTitle(e.target.value)}
                  type="text"
                  required
                  helperText={formErrors.title}
                  error={formErrors.title ? true : false}
                />
              </Box>
            </Grid>
          </Grid>
          <Box textAlign="center" py={2}>
            {loading ? (
              <CircularProgress size={24} />
            ) : (
              <>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  SUBMIT
                </Button>
              </>
            )}
          </Box>
        </form>
      </Box>
    </div>
  );
};

export default AddCategory;
