import {
  Card,
  CardContent,
  Container,
  Divider,
  Grid,
  Typography,
  Button,
} from "@material-ui/core";
import { Paper, Skeleton, Stack } from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import React, { useEffect, useState } from "react";
import ImagePlaceHolder from "../../Components/GibsonsComponents/ImagePlaceHolder";
import Constants from "../../Components/Helpers/Constants";
import allStyles from "../../Styles/allStyles";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import { addToCart } from "../../Redux/Actions";
import { connect } from "react-redux";
import CategoriesComponent from "./CategoriesComponent";
import ProductsLoop from "./ProductsLoop";
import { useParams } from "react-router-dom";

const ShopCategory = (props) => {
  // get id from url
  const { id } = useParams();

  const classes = allStyles();
  const navigate = useNavigate();

  const [products, setProducts] = useState(null);
  const [loadingProducts, setLoadingProducts] = useState(true);

  function getProducts() {
    setLoadingProducts(true);
    axios
      .get(
        `${Constants.api}/api/products/region/${props.location}/category/${id}`,
        {
          Accept: "application/json",
        }
      )
      .then((r) => {
        setProducts(r.data);
        setLoadingProducts(false);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  function addAndGoToCart(product) {
    props.addToCart(product, 1);
    navigate(`/cart`);
  }

  useEffect(() => {
    getProducts();
  }, [props.location, id]);

  return (
    <Container>
      <Box my={2}>
        <Typography variant="h4" align="center">
          Shop
        </Typography>
      </Box>
      <Box my={2}>
        <Divider />
      </Box>
      <Grid container>
        <Grid item xs={12} md={4}>
          <Box pr={4}>
            <CategoriesComponent />
          </Box>
        </Grid>
        <Grid item xs={12} md={8}>
          {loadingProducts ? (
            <>
              <Skeleton animation="pulse" />
              <Skeleton animation="pulse" />
              <Skeleton animation="pulse" />
            </>
          ) : (
            <ProductsLoop products={products} addAndGoToCart={addAndGoToCart} />
          )}
        </Grid>
      </Grid>
    </Container>
  );
};

// mapStateToProps
const mapStateToProps = (state) => {
  return {
    location: state.location,
  };
};

// mapDispatchToProps
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ addToCart }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ShopCategory);
