import { CssBaseline, ThemeProvider } from "@material-ui/core";
import { createTheme } from "@mui/material/styles";

import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import About from "../../Pages/About/About.js";
import Account from "../../Pages/Account/Account.js";
import AddProduct from "../../Pages/Admin/AddProduct/AddProduct.js";
import AdminProductDetails from "../../Pages/Admin/AddProduct/AdminProductDetails.js";
import AdminViewProducts from "../../Pages/Admin/AdminViewProducts/AdminViewProducts.js";
import AddCategory from "../../Pages/Admin/Categories/AddCategory.js";
import ViewCategories from "../../Pages/Admin/Categories/ViewCategories.js";
import ViewOrders from "../../Pages/Admin/Orders/ViewOrders.js";
import Shippingzone from "../../Pages/Admin/Shipping/Shippingzone.js";
import ShippingzoneProducts from "../../Pages/Admin/Shipping/ShippingzoneProducts.js";
import ShippingzonesList from "../../Pages/Admin/Shipping/ShippingzonesList.js";
import ViewUsers from "../../Pages/Admin/Users/ViewUsers.js";
import Blog from "../../Pages/Blog/Blog.js";
import Cart from "../../Pages/Cart/Cart.js";
import Checkout from "../../Pages/Checkout/Checkout.js";
import Payment from "../../Pages/Checkout/Payment.js";
import PaymentSuccess from "../../Pages/Checkout/PaymentSuccess.js";
import Contact from "../../Pages/Contact/Contact.js";
import Distributors from "../../Pages/Distributors/Distributors.js";
import Index from "../../Pages/Index/Index.js";
import Login from "../../Pages/Login/Login.js";
import Logout from "../../Pages/Login/Logout.js";
import PasswordReset from "../../Pages/PasswordReset/PasswordReset.js";
import Register from "../../Pages/Register/Register.js";
import Product from "../../Pages/Shop/Product.js";
import Shop from "../../Pages/Shop/Shop.js";
import ShopCategory from "../../Pages/Shop/ShopCategory.js";
import AdminRoutes from "./AdminRoutes.js";
import Layout from "./Layout.js";
import ProtectedRoutes from "./ProtectedRoutes.js";
import ProtectedRoutesWhileLoggedOut from "./ProtectedRoutesWhileLoggedOut.js";
import ScrollToTop from "./ScrollToTop.js";
import AccountRoutes from "./AccountRoutes.js";
import Orders from "../../Pages/Account/Orders.js";
import ShowOrder from "../../Pages/Account/ShowOrder.js";
import AdminShowOrder from "../../Pages/Admin/Orders/AdminShowOrder.js";
import PasswordlessLogin from "../../Pages/PasswordReset/PasswordlessLogin.js";
import NewPassword from "../../Pages/Account/NewPassword.js";
import ShippingzoneCountriesEdit from "../../Pages/Admin/Shipping/ShippingzoneCountriesEdit.js";

const theme = createTheme({
  palette: {
    background: {
      default: "#ffffff",
    },
    primary: {
      main: "#f8931d",
    },
    secondary: {
      main: "#722623",
    },
    // #f8931d
    // #722623
  },
  typography: {
    fontFamily: "Quicksand",
    fontWeightLight: 400,
    fontWeightRegular: 500,
    fontWeightMedium: 600,
    fontWeightBold: 700,
  },
  overrides: {
    MuiListItemIcon: {
      root: {
        minWidth: 36,
      },
    },
    MuiInputBase: {
      input: {
        // background: "",
        // borderColor: "yellow",
        // border: "2px solid",
      },
    },
  },
});

const AppRoutes = (props) => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <ScrollToTop />
        <Layout>
          <Routes>
            <Route path="/" element={<Index />} />
            <Route path="/about" element={<About />} />
            <Route path="/shop" element={<Shop />} />
            <Route path="/shop/category/:id" element={<ShopCategory />} />
            <Route path="/distributors" element={<Distributors />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/payment/:orderId" element={<Payment />} />
            <Route path="/paymentSuccess/:orderId" element={<PaymentSuccess />} />
            <Route
              path="/download"
              element={
                <a
                  href="https://play.google.com/store/apps/details?id=com.gibscoffee.app"
                  target="_self"
                  rel="noopener noreferrer"
                  onClick={() => {
                    window.open(
                      "https://play.google.com/store/apps/details?id=com.gibscoffee.app",
                      "_self"
                    );
                  }}
                >
                  Download
                </a>
              }
            />
            <Route path="/shop/product/:id" element={<Product />} />
            {/* Account routes */}
            <Route path="/account" element={<AccountRoutes component={<Account />} />} />
            <Route path="/account/password" element={<AccountRoutes component={<NewPassword />} />} />
            <Route path="/account/orders" element={<AccountRoutes component={<Orders />} />} />
            <Route path="/account/orders/:orderId" element={<AccountRoutes component={<ShowOrder />} />} />
            {/* ProtectedRoutesWhileLoggedIn */}
            <Route path="/login" element={<ProtectedRoutes component={<Login />} />} />
            <Route path="/reset-password" element={<ProtectedRoutes component={<PasswordlessLogin />} />} />
            <Route path="/register" element={<ProtectedRoutes component={<Register />} />} />
            <Route path="/password-reset" element={<ProtectedRoutes component={<PasswordReset />} />} />
            {/* ProtectedRoutesWhileLoggedOut */}
            <Route path="/logout" element={<ProtectedRoutesWhileLoggedOut component={<Logout />} />} />
            <Route path="/checkout" element={<ProtectedRoutesWhileLoggedOut component={<Checkout />} />} />
            <Route path="/admin/add-product" element={<AdminRoutes component={<AddProduct />} />} />
            <Route path="/admin" element={<AdminRoutes component={<AdminViewProducts />} />} />
            <Route path="/admin/product/:productId" element={<AdminRoutes component={<AdminProductDetails />} />} />
            <Route path="/admin/add-category" element={<AdminRoutes component={<AddCategory />} />} />
            <Route path="/admin/view-categories" element={<AdminRoutes component={<ViewCategories />} />} />
            <Route path="/admin/users" element={<AdminRoutes component={<ViewUsers />} />} />
            <Route path="/admin/orders" element={<AdminRoutes component={<ViewOrders />} />} />
            <Route path="/admin/orders/:orderId" element={<AdminRoutes component={<AdminShowOrder />} />} />
            <Route path="/admin/shipping-zones/new" element={<AdminRoutes component={<Shippingzone />} />} />
            <Route path="/admin/shipping-zones/list/:id" element={<AdminRoutes component={<ShippingzoneProducts />} />} />
            <Route path="/admin/shipping-zones/list" element={<AdminRoutes component={<ShippingzonesList />} />} />
            <Route path="/admin/shipping-zones/list/:id/edit" element={<AdminRoutes component={<ShippingzoneCountriesEdit />} />} />
          </Routes>
        </Layout>
      </Router>
    </ThemeProvider>
  );
};


export default AppRoutes;
