import { FormControl } from "@material-ui/core";
import { styled } from "@material-ui/styles";

const CustomSelectField = styled(FormControl)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(1),
  "&:before": {
    borderColor: theme.palette.primary.main,
  },
  "&:after": {
    borderColor: theme.palette.primary.main,
  },

  "& label.Mui-focused": {
    color: theme.palette.primary.main,
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "green",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: theme.palette.secondary.light,
    },
    /* "&:hover fieldset": {
      borderColor: "yellow",
    },
    "&.Mui-focused fieldset": {
      borderColor: "green",
    }, */
  },
}));

export default CustomSelectField;
