import {
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import { Box } from "@mui/system";
import axios from "axios";
import React, { useEffect, useState } from "react";
import CustomSelectField from "../../../Components/GibsonsComponents/CustomSelectField";
import CustomTextField from "../../../Components/GibsonsComponents/CustomTextField";
import Constants from "../../../Components/Helpers/Constants";
import { useQuill } from 'react-quilljs';
import 'quill/dist/quill.snow.css';

const EditProducts = (props) => {
  let { product } = props;
  const [title, setTitle] = useState(product.title);
  const [description, setDescription] = useState(product.description);
  const [price, setPrice] = useState(product.price);
  const [units, setUnits] = useState(product.units);
  const [unitsValue, setUnitsValue] = useState(product.units_value);
  const [categoryId, setCategoryId] = useState(product.category_id);
  const [minQty, setMinQty] = useState(product.min_qty);
  const [region, setRegion] = useState(product.region);
  const [currency, setCurrency] = useState(product.currency);
  const [shown, setShown] = useState(product.shown);
  const [loading, setLoading] = useState(false);
  const [formErrors, setFormErrors] = useState(false); // TODO
  const { quill, quillRef } = useQuill();

  function editProduct(e) {
    e.preventDefault();
    const fd = new FormData();
    fd.append("id", product.id);
    fd.append("title", title);
    fd.append("description", description);
    fd.append("price", price);
    fd.append("units", units);
    fd.append("units_value", unitsValue);
    fd.append("category_id", categoryId);
    fd.append("min_qty", minQty);
    fd.append("region", region);
    fd.append("currency", currency);
    fd.append("shown", shown);
    setLoading(true);
    axios
      .post(`${Constants.api}/api/admin/edit-product`, fd, {
        headers: {
          Accept: "application/json",
        },
      })
      .then((r) => {
        setLoading(false);
      })
      .catch((error) => {
        console.log(error.response.data.error);
        if (error.response.data.error != undefined) {
          setFormErrors(error.response.data.error);
        }
        setLoading(false);
      });
  }

  useEffect(() => {
    if (quill) {
      quill.clipboard.dangerouslyPasteHTML(description); // <- this is where the initial HTML string can be passed

      quill.on('text-change', (delta, oldDelta, source) => {
        // console.log('Text change!');
        // console.log(quill.getText()); // Get text only
        // console.log(quill.getContents()); // Get delta contents
        // console.log(quill.root.innerHTML); // Get innerHTML using quill <- This is what you want to send to DB
        // console.log(quillRef.current.firstChild.innerHTML); // Get innerHTML using quillRef
        setDescription(quill.root.innerHTML);
      });
    }
  }, [quill]);

  return (
    <>
      <Box py={1}>
        <form onSubmit={editProduct}>
          <Grid container>
            <Grid item xs={12} md={6}>
              <Box px={1}>
                <CustomTextField
                  value={title}
                  label="Title"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  onChange={(e) => setTitle(e.target.value)}
                  type="text"
                  required
                  helperText={formErrors.title}
                  error={formErrors.title ? true : false}
                />

                <CustomSelectField variant="outlined" fullWidth required>
                  <InputLabel>Region</InputLabel>
                  <Select
                    label="Region"
                    value={region}
                    onChange={(e) => {
                      setRegion(e.target.value);
                    }}
                  >
                    <MenuItem value={"kenya"}>Kenya</MenuItem>
                    <MenuItem value={"rest of the world"}>
                      Rest of the world
                    </MenuItem>
                  </Select>
                </CustomSelectField>

                <CustomTextField
                  value={price}
                  label="Price"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  onChange={(e) => setPrice(e.target.value)}
                  type="number"
                  inputProps={{
                    step: ".01",
                  }}
                  required
                  helperText={formErrors.price}
                  error={formErrors.price ? true : false}
                />

                <CustomSelectField variant="outlined" fullWidth required>
                  <InputLabel>Currency</InputLabel>
                  <Select
                    label="Currency"
                    value={currency}
                    onChange={(e) => setCurrency(e.target.value)}
                  >
                    <MenuItem value={"kshs"}>Kshs</MenuItem>
                    <MenuItem value={"usd"}>USD</MenuItem>
                  </Select>
                </CustomSelectField>


                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={Boolean(shown)}
                        onChange={(e) => {
                          setShown(e.target.checked);
                        }}
                      />
                    }
                    label="Show product"
                  />
                </FormGroup>

                
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box px={1}>
                <CustomTextField
                  value={minQty == null ? "" : minQty}
                  label="Minimum Qty"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  onChange={(e) => setMinQty(e.target.value)}
                  type="number"
                  helperText={formErrors.min_qty}
                  error={formErrors.min_qty ? true : false}
                />

                <CustomSelectField variant="outlined" fullWidth>
                  <InputLabel>Category</InputLabel>
                  <Select
                    label="Units"
                    value={categoryId == null ? "" : categoryId}
                    onChange={(e) => setCategoryId(e.target.value)}
                  >
                    {props.categories.map((category) => (
                      <MenuItem key={category.id} value={category.id}>
                        {category.title}
                      </MenuItem>
                    ))}
                  </Select>
                </CustomSelectField>

                <CustomSelectField variant="outlined" fullWidth>
                  <InputLabel>Units</InputLabel>
                  <Select
                    label="Units"
                    value={units == null ? "gm" : units}
                    onChange={(e) => setUnits(e.target.value)}
                  >
                    <MenuItem value={"kg"}>kg</MenuItem>
                    <MenuItem value={"gm"}>gm</MenuItem>
                    <MenuItem value={"mg"}>mg</MenuItem>
                    <MenuItem value={"lbs"}>lbs</MenuItem>
                    <MenuItem value={"pack"}>pack</MenuItem>
                    <MenuItem value={"piece"}>piece</MenuItem>
                    <MenuItem value={"unit"}>unit</MenuItem>
                    <MenuItem value={"litre"}>litre</MenuItem>
                    <MenuItem value={"ml"}>ml</MenuItem>
                  </Select>
                </CustomSelectField>

                <CustomTextField
                  value={unitsValue == null ? "" : unitsValue}
                  label="Units value"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  onChange={(e) => setUnitsValue(e.target.value)}
                  type="number"
                  helperText={formErrors.units_value}
                  error={formErrors.units_value ? true : false}
                />

                
              </Box>
            </Grid>

            <Grid item xs={12}>
                <Box px={1}>
                  <label htmlFor="description">Description</label>
                  <div style={{ width: "100%" }}>
                    <div ref={quillRef} />
                  </div>
                </Box>
              </Grid>

          </Grid>
          <Box textAlign="center" py={2}>
            {loading ? (
              <CircularProgress size={24} />
            ) : (
              <>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  SUBMIT
                </Button>
              </>
            )}
          </Box>
        </form>
      </Box>
    </>
  );
};

export default EditProducts;
