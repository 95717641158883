var items = [
  [
    {
      name: "1",
      type: "image",
      imagePath: "/carousel-aa.png",
      buttonText: "Shop",
      url: "/shop",
    },
    {
      name: "2",
      type: "text",
      heading: "NOW SHIPPING WORLDWIDE",
      description:
        "We are now in USA for faster delivery in N. America. We also ship from Kenya to the rest of the world and anywhere within Kenya.",
      gridClass: "cardOrange",
      url: "/shop",
      buttonText: "Shop Now",
    },
    {
      name: "3",
      type: "text",
      heading: "100% ARABICA COFFEE",
      description: "Grown and harvested near the slopes of Mt. Kenya",
      gridClass: "cardOrange2",
      url: "/shop",
      buttonText: "Buy Now",
    },
  ],
  [
    {
      name: "2",
      type: "text",
      heading: "GET YOUR COFFEE MAKER",
      description:
      "French presses in various colors and styles are in stock.",
      gridClass: "cardOrange",
      buttonText: "Buy Now",
      url: "/shop",

    },
    {
      name: "3",
      type: "text",
      heading: "NEED NEW COFFEE FILTERS?",
      description: "Grab yourself some high quality filters for your coffee maker today.",
      gridClass: "cardOrange2",
      buttonText: "Buy Now",
      url: "/shop",
    },
    {
      name: "1",
      url: "/shop",
      type: "image",
      imagePath: "/carousel-coffee-maker.png",
      buttonText: "Shop",
    },
  ],
];

export default items;
