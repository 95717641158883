import { LocalCafe, Person, ShoppingCart } from "@material-ui/icons";
import { Login } from "@mui/icons-material";

const menuItems = [
  {
    text: "Home",
    icon: <LocalCafe />,
    path: "/",
  },
  {
    text: "About",
    icon: <LocalCafe />,
    path: "/about",
  },
  {
    text: "Shop",
    icon: <LocalCafe />,
    path: "/shop",
  },
  {
    text: "Distributors",
    icon: <LocalCafe />,
    path: "/distributors",
  },
  {
    text: "Blog",
    icon: <LocalCafe />,
    path: "/blog",
  },
  {
    text: "Contact",
    icon: <LocalCafe />,
    path: "/contact",
  },
  {
    text: "Cart",
    icon: <ShoppingCart />,
    path: "/cart",
    desktop: false,
  },
  {
    text: "Login",
    icon: <Login />,
    path: "/login",
    desktop: false,
    hideLoggedIn: true,
  },{
    text: "Register",
    icon: <Person />,
    path: "/register",
    desktop: false,
    hideLoggedIn: true,
  },{
    text: "Account",
    icon: <Person />,
    path: "/account",
    desktop: false,
    hideLoggedOut: true,
  },{
    text: "Log Out",
    icon: <Person />,
    path: "/logout",
    desktop: false,
    hideLoggedOut: true,
  },
  ,
];

export default menuItems;