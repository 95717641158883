import React, { useState } from "react";
import {
  AppBar,
  Avatar,
  Badge,
  Button,
  ButtonBase,
  Container,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  styled,
  Toolbar,
  Typography,
} from "@material-ui/core";

import useStyles from "./LayoutCSS";
import { useNavigate, useLocation } from "react-router-dom";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

import { ArrowDropDown, EditLocation, Menu } from "@material-ui/icons";
import { GetApp, Person, ShoppingCart } from "@mui/icons-material";
import { orange } from "@material-ui/core/colors";
import { Box, margin } from "@mui/system";
import menuItems from "../Helpers/menuItems";
import TopRightIcons from "./TopRightIcons";
import Footer from "./Footer";
import ChooseLocation from "./ChooseLocation";

const Layout = ({ children, window }) => {
  const desktopMenuItems = menuItems.filter((item) => {
    if (item.desktop === false) return false;
    return true;
  });

  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();

  // drawer stuff
  const drawerWidth = 240;

  const container =
    window !== undefined ? () => window().document.body : undefined;
  const [mobileOpen, setMobileOpen] = useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  // end of drawer stuff

  const mobileDrawerItems = (
    <List>
      {menuItems.map((item) =>
        localStorage.getItem("user-info") && item.hideLoggedIn ? (
          // "hide if logged in"
          ""
        ) : !localStorage.getItem("user-info") && item.hideLoggedOut ? (
          // "hide if logged out"
          ""
        ) : (
          <ListItem
            key={item.text}
            button
            onClick={() => {
              handleDrawerToggle();
              navigate(item.path);
            }}
            className={location.pathname == item.path ? classes.active : null}
          >
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.text} />
          </ListItem>
        )
      )}
    </List>
  );

  return (
    <div style={{ minHeight: "100vh" }}>
      {/* <ButtonBase
        className={classes.getApp}
        onClick={() => navigate("/download")}
      >
        <Typography className={classes.getAppText}>
          Our Mobile App Is Now Available
        </Typography>
        <GetApp className={classes.downloadIcon} />
      </ButtonBase> */}

      <AppBar position="static" elevation={0} color="primary">
        <Container>
          <Toolbar>
            {/* =======LOGO======= */}
            <div className={classes.logoWrapper}>
              <ButtonBase onClick={() => navigate("/")}>
                <img src="/logo.png" className={classes.logo} />
              </ButtonBase>
            </div>

            <div className={classes.linksWrapper}>
              {/* =======LINKS======= */}
              {desktopMenuItems.map((item) => (
                <Link
                  key={item.text}
                  to={item.path}
                  className={
                    location.pathname == item.path
                      ? `${classes.navLinks} ${classes.activeNavLink}`
                      : classes.navLinks
                  }
                >
                  {item.text}
                </Link>
              ))}
            </div>

            {/* =======ICONS======= */}
            <TopRightIcons
              classes={classes}
              handleDrawerToggle={handleDrawerToggle}
            />
          </Toolbar>
        </Container>
      </AppBar>

      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          classes={{ paper: classes.paper }}
        >
          {mobileDrawerItems}
        </Drawer>
      </Box>

      <ChooseLocation />

      <Box>{children}</Box>
      <Footer />
    </div>
  )
}

export default Layout;
