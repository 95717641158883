import { Container, Grid, Typography } from "@material-ui/core";
import { FormatQuote } from "@material-ui/icons";
import React from "react";

const Testimonials = () => {
  return (
    <div>
      <Container>
        <Typography
          variant="h4"
          align="center"
          style={{ marginTop: "2rem", marginBottom: "1rem" }}
        >
          Testimonials
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={12} md={6} lg={4}>
            <blockquote>
              <Typography paragraph align="center">
                <FormatQuote style={{ transform: "scaleX(-1)" }} />
              </Typography>
              <div>
                <Typography
                  variant="body1"
                  align="center"
                  style={{ marginBottom: "1rem" }}
                >
                  "Smooth, well rounded and tasty coffee. I have bought another
                  packet of beans"
                </Typography>
              </div>
              <Typography paragraph align="center" variant="caption">
                <strong>- Paul Walker</strong>
              </Typography>
            </blockquote>
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <blockquote>
              <Typography paragraph align="center">
                <FormatQuote style={{ transform: "scaleX(-1)" }} />
              </Typography>
              <div>
                <Typography
                  variant="body1"
                  align="center"
                  style={{ marginBottom: "1rem" }}
                >
                  "I found what I was looking for easily on the site. I am
                  definitely enjoying Gibsons Coffee and have been since it was
                  first gifted it to me several months ago. Great to have a
                  chance to buy it closer to DC, than waiting to go to
                  Nairobi!!!"
                </Typography>
              </div>
              <div>
                <Typography
                  variant="body1"
                  align="center"
                  style={{ marginBottom: "1rem" }}
                >
                  "I heard about the US options via my friend and placed my
                  order as soon as I could."
                </Typography>
              </div>
              <Typography paragraph align="center" variant="caption">
                <strong>- Wangechi T</strong>
              </Typography>
            </blockquote>
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <blockquote>
              <Typography paragraph align="center">
                <FormatQuote style={{ transform: "scaleX(-1)" }} />
              </Typography>
              <div>
                <Typography
                  variant="body1"
                  align="center"
                  style={{ marginBottom: "1rem" }}
                >
                  "I like how well it was sealed and packaged."
                </Typography>
              </div>
              <Typography paragraph align="center" variant="caption">
                <strong>- Juliet Kinyua</strong>
              </Typography>
            </blockquote>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Testimonials;
