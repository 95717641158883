import React from "react";
import { Box, Container, Grid, Typography } from "@material-ui/core";

const Contact = () => {
  return (
    <div className="gbContact">
      <Container
        style={{
          // background: "#EEE",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Grid
          container
          spacing={2}
          style={
            {
              // background: "#000",
            }
          }
        >
          <Grid item xs={12} lg={6}>
            <Box
              className="contactWrapper"
              p={4}
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              height="100%"
            >
              <Typography color="primary" variant="h3" align="center">
                Get in touch with us
              </Typography>
              {/* paragraphs */}
              <Typography variant="h6" align="center">
                Email: info[at]gibscoffee.com
              </Typography>
              <Typography variant="h6" align="center">
                Phone: +254 728 981 656 / +254 728 981 678
              </Typography>
              <Typography variant="h6" align="center">
                Postal Address: Mathew Mugo,
              </Typography>
              <Typography variant="h6" align="center">
                Gibsons Coffee Ltd,
              </Typography>
              <Typography variant="h6" align="center">
                P.O. BOX 9592-00300,
              </Typography>
              <Typography variant="h6" align="center">
                Nairobi, Kenya.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Box className="contactWrapper" p={4} height="100%">
              {/* Content for the new box */}
              <img
                src="/images/qr.jpg"
                // width="100%"
                style={{
                  margin: "auto",
                  maxWidth: "100%",
                  height: "auto",
                  display: "block",
                  borderRadius: "20px",
                  boxShadow: "0px 0px 11px 1px #878787",
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Contact;
