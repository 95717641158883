import { CircularProgress, Typography, Box, Grid } from "@material-ui/core";
import React, { useEffect } from "react";
import Helpers from "../../Components/Helpers/Helpers";
import { useNavigate } from "react-router-dom";

function Logout() {
  const navigate = useNavigate();
  useEffect(() => {
    Helpers.logout();
    if (!Helpers.isLoggedIn()) navigate("/login");
  }, []);

  return (
    <div>
      <Box sx={{ pt: 2 }}>
        <Grid container justifyContent="center">
          <Grid item xs={6}>
            <Box textAlign="center">
              <Typography>Logging you out...</Typography>
              <CircularProgress size={24} />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}

export default Logout;
