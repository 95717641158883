import React from "react";
import { Button } from "@material-ui/core";
import Swal from "sweetalert2";
import Constants from "../../../Components/Helpers/Constants";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const DeleteProduct = (props) => {
  const navigate = useNavigate();

  function removeProduct() {
    Swal.fire({
      title: "Are you sure?",
      text: "Confirm if you want to permanently delete!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .get(
            `${Constants.api}/api/admin/product/delete/${props.product.id}`,
            {
              Accept: "application/json",
            }
          )
          .then((r) => {
            navigate("/admin")
          })
          .catch((e) => {
            console.log(e.response.data);
          });

      }
    });
  }

  return (
    <>
      <Button
        onClick={removeProduct}
        color="primary"
        variant="outlined"
        size="small"
      >
        Remove Product
      </Button>
    </>
  );
};

export default DeleteProduct;
