import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Typography,
} from "@material-ui/core";
import { Skeleton } from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import CustomTextField from "../../Components/GibsonsComponents/CustomTextField";
import Constants from "../../Components/Helpers/Constants";
import Swal from "sweetalert2";

const NewPassword = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [email, setEmail] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState("");
  const user = JSON.parse(localStorage.getItem("user-info"));

  const editPassword = (e) => {
    setError("");
    e.preventDefault();
    // if(password == "") e.preventDefault();
    setSubmitting(true);
    // TODO: Add form validation here
    if (password !== confirmPassword) {
      setError("Passwords do not match");
      Swal.fire({
        title: "Failed!",
        text: "Passwords do not match. Try again.",
        icon: "error",
        confirmButtonText: "Ok",
      });
      setSubmitting(false);
      return;
    }
    axios
      .post(
        `${Constants.api}/api/set-new-password`,
        {
          password,
          confirmPassword,
          email,
        },
        {
          Accept: "application/json",
        }
      )
      .then((r) => {
        console.log(r);
        setSubmitting(false);
        // use swal to tell the user password was successfully set
        Swal.fire({
          title: "Success!",
          text: "Password was succesfully reset.",
          icon: "success",
          confirmButtonText: "Ok",
        });
        setPassword("")
        setConfirmPassword("")
      })
      .catch((e) => {
        console.log(e);
        Swal.fire({
          title: "Failed!",
          text: "Password was not reset. " + e,
          icon: "error",
          confirmButtonText: "Ok",
        });
        setError("An error occurred. Please try again.");
        setSubmitting(false);
      });
  };

  useEffect(() => {
    // set email
    setEmail(user.email);
  }, []);

  return (
    <>
      <Box py={1}>
        <Typography align="center" variant="h5">
          Password
        </Typography>
      </Box>
      <Divider />
      <Box py={1}>
        <form onSubmit={editPassword}>
          <CustomTextField
            label="New Password"
            variant="outlined"
            fullWidth
            margin="normal"
            onChange={(e) => setPassword(e.target.value)}
            type="password"
            required
            value={password}
          />

          <CustomTextField
            label="Confirm Password"
            variant="outlined"
            fullWidth
            margin="normal"
            onChange={(e) => setConfirmPassword(e.target.value)}
            type="password"
            required
            value={confirmPassword}
          />

          <Button
            variant="contained"
            color="primary"
            type="submit"
            size="large"
            fullWidth
            disableElevation
          >
            {submitting ? (
              <CircularProgress color="secondary" />
            ) : (
              <span>SUBMIT</span>
            )}
          </Button>

          {error && (
            <Typography color="error" align="center">
              {error}
            </Typography>
          )}
        </form>
      </Box>
    </>
  );
};

export default NewPassword;
