import React from "react";
import { Typography, Container, Grid } from "@material-ui/core";
import { Box } from "@mui/system";
import MapDetails from "./MapDetails";
import ContactDetails from "./ContactDetails";

const Distributors = () => {
  return (
    <div className="distributorsWrapper">
      <Container>
        <Box py={2} mb={2}>
          <Typography variant="h6" color="primary" align="center">
            Get In Touch With Our International Distributors
          </Typography>
        </Box>

        {/* Start contact here */}

        <Box my={2}>
          <Grid container align="center">
            <Grid item xs={12} md={6}>
              <ContactDetails>
                <Typography variant="h3" align="center" color="primary">
                  Australia
                </Typography>
                <Typography paragraph>
                  AUSTRALIA GIBSONS COFFEE DISTRIBUTORS
                </Typography>
                <Typography paragraph>Sea Hope Of Perth</Typography>
                <Typography paragraph>Contact person : Amir</Typography>
                <Typography paragraph>
                  28 Meadowbrook Drive, Parkwood, WA, Perth, Australia, 6147{" "}
                </Typography>
                <Typography paragraph>
                  Email:{" "}
                  <a
                    href="mailto:seahopeau@gmail.com"
                    style={{ color: "#F8931D" }}
                  >
                    seahopeau@gmail.com
                  </a>
                </Typography>
              </ContactDetails>
            </Grid>
            <Grid item xs={12} md={6}>
              <MapDetails>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3382.03451874034!2d115.91136011466773!3d-32.04125708120038!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2a32bdbaf10f5f6f%3A0x53a53f833955cf04!2s28+Meadowbrook+Dr%2C+Parkwood+WA+6147%2C+Australia!5e0!3m2!1sen!2sus!4v1498653270934"
                  width="80%"
                  height={270}
                  frameBorder={0}
                  style={{ border: 0 }}
                  allowFullScreen
                />
              </MapDetails>
            </Grid>
          </Grid>
        </Box>

        {/* End contact here */}

        {/* Start contact here */}

        <Box my={2}>
          <Grid container align="center">
            <Grid item xs={12} md={6}>
              <ContactDetails>
                <Typography variant="h3" align="center" color="primary">
                  South Africa
                </Typography>
                <Typography paragraph>
                  SOUTH AFRICA AND NEIGHBOURING COUNTRIES
                </Typography>
                <Typography paragraph>Cecilias Coffee</Typography>
                <Typography paragraph>
                  Contact person : Roy and Cecilia Kerr
                </Typography>
                <Typography paragraph>
                  Bakkerskloof Road, Somerset West 7130, Cape Town - South
                  Africa <br />
                </Typography>
                <Typography paragraph>
                  Email:{" "}
                  <a
                    href="mailto:ceciliascoffee@gmail.com "
                    style={{ color: "#F8931D" }}
                  >
                    ceciliascoffee@gmail.com
                  </a>
                </Typography>
              </ContactDetails>
            </Grid>
            <Grid item xs={12} md={6}>
              <MapDetails>
                <iframe
                  src="https://maps.google.co.ke/maps?f=q&source=s_q&hl=en&geocode=&q=Bakkerskloof+Road,++++++++++++++++Somerset+West+7130,++++++++++++++++Cape+Town+-+South+Africa&aq=&sll=39.697199,-86.039568&sspn=0.00435,0.006598&ie=UTF8&hq=&hnear=Bakkerskloof+Rd,+Somerset+West,+Cape+Town,+Western+Cape+7130,+South+Africa&t=m&z=14&ll=-34.050409,18.82573&output=embed"
                  width="80%"
                  height={270}
                  frameBorder={0}
                  style={{ border: 0 }}
                  allowFullScreen
                />
              </MapDetails>
            </Grid>
          </Grid>
        </Box>

        {/* End contact here */}

        {/* Start contact here */}

        <Box my={2}>
          <Grid container align="center">
            <Grid item xs={12} md={6}>
              <ContactDetails>
                <Typography variant="h3" align="center" color="primary">
                  FINLAND
                </Typography>
                <Typography paragraph>FINLAND AND NEIGBOURING COUNTRIES</Typography>
                <Typography paragraph>Gibsonscafé</Typography>
                <Typography paragraph>
                  Contact person : Jorma Reinikainen
                </Typography>
                <Typography paragraph>
                  Lähteenpellontie 1, 15270, KUKKILA, FINLAND
                  <br />
                  Tel: +358400421453 / +358415075083
                </Typography>
                <Typography paragraph>
                  Email:{" "}
                  <a
                    style={{ color: "#F8931D" }}
                    href="mailto:jorma.reinikainen@gibsonscafe.fi"
                  >
                    jorma.reinikainen@gibsonscafe.fi
                  </a>{" "}
                  <br />
                  <a
                    style={{ color: "#F8931D" }}
                    href="mailto:joonas.reinikainen@gibsonscafe.fi"
                  >
                    joonas.reinikainen@gibsonscafe.fi
                  </a>{" "}
                </Typography>
              </ContactDetails>
            </Grid>
            <Grid item xs={12} md={6}>
              <MapDetails>
                <iframe
                  src="https://maps.google.co.ke/maps?f=q&source=s_q&hl=en&geocode=&q=L%C3%A4hteenpellontie+1,+15270,+KUKKILA,+FINLAND&aq=&sll=-34.050409,18.82573&sspn=0.009369,0.013196&ie=UTF8&hq=&hnear=L%C3%A4hteenpellontie+1,+Hollola,+Finland&t=m&z=14&ll=61.036606,25.659244&output=embed"
                  width="80%"
                  height={270}
                  frameBorder={0}
                  style={{ border: 0 }}
                  allowFullScreen
                />
              </MapDetails>
            </Grid>
          </Grid>
        </Box>

        {/* End contact here */}

      </Container>
    </div>
  );
};

export default Distributors;
