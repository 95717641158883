const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxWidth: 800,
  minHeight: 500,
  backgroundColor: "#FFF",
  border: "none",
  borderRadius: 1,
  boxShadow: 24,
  p: 4,
  // overFlowY: "auto",
};

export default modalStyle;