import {
  Button,
  CircularProgress,
  Container,
  Divider,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import { Box } from "@mui/system";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import CustomSelectField from "../../Components/GibsonsComponents/CustomSelectField";
import Constants from "../../Components/Helpers/Constants";
import Helpers from "../../Components/Helpers/Helpers";
import DeliveryDetailsForm from "./DeliveryDetailsForm";
import { Skeleton } from "@mui/material";

const Checkout = (props) => {
  const navigate = useNavigate();
  const [countyOrCountry, setCountyOrCountry] = useState("");
  const [shippingZones, setShippingZones] = useState([]);
  const [shippingTotal, setShippingTotal] = useState(0);
  const [user, setUser] = useState({});
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    Helpers.deleteLocalStorage("returnTo");
  }, []);

  const computeShippingrate = (place) => {
    setCountyOrCountry(place);
    let shippingTotal = 0;
    // loop through all items in the cart
    props.cart.items.forEach((item) => {
      var zoneid = null; // TODO: see if it's possible to get the zone id more easily

      // loop through all shipping rates
      shippingZones.forEach((shippingZone) => {
        shippingZone.ship_to_county_or_country.forEach((shippingTo) => {
          if (shippingTo.county_or_country == place) {
            zoneid = shippingTo.shippingzone_id;
          }
        });

        // loop through the rates
        shippingZone.shippingrate.forEach((rate) => {
          if (rate.shippingzone_id == zoneid && rate.product_id == item.id) {
            shippingTotal += rate.rate * item.quantity;
          }
        });
      });
    });

    // if currency is kshs and shipping is more than kshs 1000, setShippingTotal to 1000
    if (props.cart.items[0].currency == "kshs" && shippingTotal > 1000) {
      shippingTotal = 1000;
    }

    // if currency is kshs and shipping is less than kshs 300, setShippingTotal to 300
    if (props.cart.items[0].currency == "kshs" && shippingTotal < 300) {
      shippingTotal = 300; // TODO: uncomment this line in the future
      // shippingTotal = 0;
    }

    setShippingTotal(shippingTotal);
  };

  const confirmUserDetails = () => {
    let localUser = JSON.parse(Helpers.getLocalStorage("user-info"));
    if (localUser) {
      // alert(JSON.stringify(localUser));
      // axios get api/user/details
      axios
        .get(`${Constants.api}/api/user/details`, {
          Accept: "application/json",
          params: {
            id: localUser.id,
          },
        })
        .then((r) => {
          if (r.data !== null) {
            localStorage.setItem("user-info", JSON.stringify(r.data));
          }
          setUser(r.data);
        })
        .catch((e) => {
          console.log(e.response.data);
        });
    } else {
      console.log("no user");
    }
  };

  const [loading, setLoading] = useState(true);

  const getShippingzones = async () => {
    let shippingzones = JSON.parse(Helpers.getLocalStorage("shippingRates"));

    try {
      const response = await axios.get(
        `${Constants.api}/api/admin/shippingrates/region/${props.location}`,
        {
          Accept: "application/json",
        }
      );

      localStorage.setItem("shippingRates", JSON.stringify(response.data));
      shippingzones = response.data;
      setShippingZones(shippingzones);
      setLoading(false);
    } catch (e) {
      console.log(e.response.data);
    }
  };

  useEffect(() => {
    getShippingzones();
    confirmUserDetails();
  }, []);

  const checkout = (e) => {
    e.preventDefault();
    // sumit form
    setSubmitting(true);

    axios
      .post(
        `${Constants.api}/api/checkout/create-order`,
        {
          cart: props.cart,
          shippingTotal: shippingTotal,
          user,
          country: countyOrCountry,
        },
        {
          headers: {
            Accept: "application/json",
          },
        }
      )
      .then((r) => {
        setSubmitting(false);
        window.location.href = `${Constants.api}/payment/${r.data.id}`;
      })
      .catch((error) => {
        setSubmitting(false);
        console.log(error);
      });
  };

  if (props.cart.items == 0) {
    navigate("/cart");
    return <div>There are no products</div>;
  }

  return (
    <Container>
      <Box mb={2}>
      {loading ? 
      <>
        <Skeleton animation="pulse" />
        <Skeleton animation="pulse" />
        <Skeleton animation="pulse" />
      </>
      : (
        <form onSubmit={checkout}>
          <Grid container>
            <Grid item xs={12} md={6}>
              <Box mx={2}>
                <h1>Your delivery details</h1>
                <Divider />
                <DeliveryDetailsForm user={user} setUser={setUser} />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box mx={2}>
                <h1>Payment</h1>
                <Divider />

                <h2>
                  Subt-total:{" "}
                  {Constants.currency(
                    props.cart.items[0].currency,
                    props.cart.cartTotal
                  )}
                </h2>
                <Typography variant="subtitle2">
                  Choose location to calculate shipping
                </Typography>

                <CustomSelectField
                  variant="outlined"
                  fullWidth
                  size="small"
                  margin="none"
                  style={{ marginTop: "0" }}
                >
                  <InputLabel>
                    {props.location == "kenya" ? "County" : "Country"}
                  </InputLabel>
                  <Select
                    label="shippingzone"
                    required
                    value={countyOrCountry}
                    onChange={(e) => computeShippingrate(e.target.value)}
                  >
                    {shippingZones.map((zone) =>
                      // order zone.ship_to_county_or_country by country.county_or_country
                      zone.ship_to_county_or_country
                        .sort((a, b) =>
                          a.county_or_country.localeCompare(b.county_or_country)
                        )
                        .map((country) => (
                          <MenuItem
                            key={country.id}
                            value={country.county_or_country}
                          >
                            {country.county_or_country}
                          </MenuItem>
                        ))
                    )}
                  </Select>
                </CustomSelectField>

                <h2>
                  Shipping:{" "}
                  {Constants.currency(
                    props.cart.items[0].currency,
                    shippingTotal
                  )}
                </h2>
                <h2>
                  Total:{" "}
                  {Constants.currency(
                    props.cart.items[0].currency,
                    shippingTotal + props.cart.cartTotal
                  )}
                </h2>

                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  size="large"
                  fullWidth
                  disableElevation
                >
                  {submitting ? (
                    <CircularProgress color="secondary" />
                  ) : (
                    <span>PAY NOW</span>
                  )}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      )}
      </Box>
    </Container>
  );
};
// connect props
const mapStateToProps = (state) => {
  return {
    cart: state.cart,
    location: state.location,
  };
};

export default connect(mapStateToProps)(Checkout);
