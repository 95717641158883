import { useNavigate } from "react-router-dom";
import useStyles from "../GibsonsComponents/LayoutCSS";

const isLoggedIn = () => {
  if (localStorage.getItem("user-info")) return true;
  return false;
};

const logout = () => {
  localStorage.clear();
};

const getUser = () => {
  if (isLoggedIn()) {
    let user = JSON.parse(localStorage.getItem("user-info"));
    return user;
  } else {
    return false;
  }
};

const setLocalStorage = (title, value) => {
  localStorage.setItem(title, value);
};
const getLocalStorage = (title) => {
  return localStorage.getItem(title);
};
const deleteLocalStorage = (title) => {
  localStorage.removeItem(title);
};

export default {
  isLoggedIn,
  logout,
  getUser,
  useStyles,
  setLocalStorage,
  getLocalStorage,
  deleteLocalStorage,
};
