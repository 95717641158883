const intialState = "kenya";

const locationReducer = (state = intialState, action) => {
  switch (action.type) {
    case "SET_LOCATION":
      // if new location is same as old location, return old state
      if (state === action.payload.location) {
        return state;
      }

      return action.payload.location;
    default:
      return state;
  }
};


export default locationReducer;