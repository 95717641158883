import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import Constants from "../../../Components/Helpers/Constants";
import { Box } from "@mui/system";
import {
  Button,
  Divider,
  Grid,
  IconButton,
  Input,
  Modal,
  Snackbar,
  Typography,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import DeleteImage from "./DeleteImage";

const EditImages = (props) => {
  const [productImages, setProductImages] = useState(null);
  const [loadingImages, setLoadingImages] = useState(true);

  const getProductImages = () => {
    axios
      .get(`${Constants.api}/api/admin/product/${props.product.id}/images`, {
        Accept: "application/json",
      })
      .then((r) => {
        setProductImages(r.data[0]);
        setLoadingImages(false);
      })
      .catch((e) => {
        console.log(e.response.data);
      });
  };

  useEffect(() => {
    getProductImages();
  }, []);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    maxWidth: 800,
    minHeight: 500,
    backgroundColor: "#FFF",
    border: "none",
    borderRadius: 1,
    boxShadow: 24,
    p: 4,
  };

  const uploadFile = (e) => {
    e.preventDefault();
    // add-product-image
    const fd = new FormData();
    fd.append("productId", props.product.id);
    fd.append("file", selectedFile);

    axios
      .post(`${Constants.api}/api/admin/add-product-image`, fd, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      .then((r) => {
        console.log(r.data);
        reset();
        props.setOpen(false);
        setOpenSnack(true);
        getProductImages();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const [selectedFile, setSelectedFile] = useState(undefined);

  const selectFile = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const ref = useRef();

  const reset = () => {
    ref.current.value = "";
  };

  const [openSnack, setOpenSnack] = useState(false);
  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={() => setOpenSnack(false)}
      >
        <Close fontSize="small" />
      </IconButton>
    </React.Fragment>
  );


  // DELETE product

  return (
    <>
      {loadingImages ? (
        <div>loading images...</div>
      ) : productImages.length < 1 ? (
        <Box>
          <Typography variant="h6" align="center">
            There are no images attached to this product.
          </Typography>
        </Box>
      ) : (
        <>
          <Box py={2}>
            <Divider />
          </Box>
          <Typography variant="h6" align="center">
            Images
          </Typography>
          <Box py={2}>
            <Divider />
          </Box>
          <Grid container justifyContent="center" alignContent="center">
            {productImages.map((image) => (
              <Grid item key={image.id} xs={12} md={3} align="center">
                <Box py={2}>
                  <img
                    src={`${Constants.api}${image.file_name}`}
                    height="150"
                  />
                  <Box>
                    <DeleteImage image={image} getProductImages={getProductImages} />
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        </>
      )}

      <Modal open={props.open} onClose={props.handleClose}>
        <Box sx={style}>
          <Typography variant="h6">Upload image</Typography>
          <Box py={2}>
            <Divider />
          </Box>
          <form onSubmit={uploadFile}>
            <label htmlFor="contained-button-file">
              <Input
                accept="image/*"
                onChange={selectFile}
                multiple
                type="file"
                inputRef={ref}
              />
            </label>
            <Box py={2}>
              <Divider />
            </Box>

            <Button variant="contained" color="primary" type="submit">
              Upload
            </Button>
          </form>
        </Box>
      </Modal>

      <Snackbar
        open={openSnack}
        autoHideDuration={6000}
        onClose={() => setOpenSnack(false)}
        message="Image successfully uploaded!"
        action={action}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        {/* <Alert
          onClose={() => setOpenSnack(false)}
          // severity="success"
          sx={{ width: "100%" }}
        >
          Image successfully uploaded!
        </Alert> */}
      </Snackbar>
    </>
  );
};

export default EditImages;
