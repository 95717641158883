import React from "react";
import {
  Button,
  CardActionArea,
  CardMedia,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import Carousel from "react-material-ui-carousel";
import items from "./items.js";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => {
  return {
    yellow: {
      background: "yellow",
    },
    MediaCaption: {
      textTransform: "uppercase",
      textOverflow: "ellipsis",
      position: "absolute",
      bottom: "0",
      padding: "15px 15px 40px",
      backgroundColor: "rgba(21,21,21,1)",
      backgroundColor: theme.palette.secondary.dark,
      color: "white",
      opacity: "0.7",
      width: "100%",
      height: "10%",
      weight: "200",
      textAlign: "center",
      fontWeight: "800",
      "&:hover": {
        opacity: "0.9",
        backgroundColor: theme.palette.secondary.dark,
      },
    },
    cardOrange: {
      background: "#FCE7CC",
      [theme.breakpoints.down("md")]: {
        paddingTop: "1rem",
        paddingBottom: "1rem",
      },
    },
    cardOrange2: {
      background: "#FCCC8C",
      [theme.breakpoints.down("md")]: {
        paddingTop: "1rem",
        paddingBottom: "1rem",
      },
    },
    cardGradient: {
      background: "linear-gradient(135deg,  #fdc530 1%,#f27535 98%)",
    },
    cardActionArea: {
      height: "100%",
      textAlign: "center",
      color: theme.palette.secondary.main,
    },
    button: {
      color: theme.palette.secondary.main,
      borderColor: theme.palette.secondary.main,
      border: "2px solid",
    },
    description: {
      margin: "1rem auto",
      paddingLeft: "1rem",
      paddingRight: "1rem",
    },
    heading: {
      color: theme.palette.primary.main,
    },
  };
});

const IndexCarousel = () => {
  const classes = useStyles();

  return (
    <div>
      <Carousel timeout={100} interval={5000} indicators={false}>
        {items.map((item, index) => (
          <Item key={index} slide={item} />
        ))}
      </Carousel>
    </div>
  );
};

function Item(items) {
  const navigate = useNavigate();
  const classes = useStyles();
  return (
    <>
      <Grid container>
        {items.slide.map((section) =>
          section.type == "text" ? (
            <Grid
              key={section.name}
              item
              sm={12}
              md={4}
              className={classes[`${section.gridClass}`]}
            >
              <CardActionArea
                className={`${classes.cardActionArea}`}
                onClick={() => navigate(section.url)}
              >
                <Typography variant="h4">{section.heading}</Typography>
                <Typography variant="h6" className={classes.description}>
                  {section.description}
                </Typography>
                <Button variant="outlined" className={classes.button}>
                  {section.buttonText}
                </Button>
              </CardActionArea>
            </Grid>
          ) : (
            <Grid
              item
              sm={12}
              md={4}
              key={section.name}
              onClick={() => navigate(section.url)}
            >
              <CardActionArea>
                <CardMedia
                  component="img"
                  height="480"
                  image={section.imagePath}
                  alt="green iguana"
                />
                <Typography className={classes.MediaCaption}>
                  {section.buttonText}
                </Typography>
              </CardActionArea>
            </Grid>
          )
        )}
      </Grid>
    </>
  );
}
export default IndexCarousel;
