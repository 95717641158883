import {
  Card,
  CardContent,
  Container,
  Divider,
  Grid,
  Typography,
  Button,
} from "@material-ui/core";
import { Pagination, Paper, Skeleton, Stack } from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import React, { useEffect, useState } from "react";
import ImagePlaceHolder from "../../Components/GibsonsComponents/ImagePlaceHolder";
import Constants from "../../Components/Helpers/Constants";
import allStyles from "../../Styles/allStyles";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import { addToCart } from "../../Redux/Actions";
import { connect } from "react-redux";
import CategoriesComponent from "./CategoriesComponent";
import ProductsLoop from "./ProductsLoop";
import { useParams } from "react-router-dom";

const Shop = (props) => {
  const classes = allStyles();
  const navigate = useNavigate();
  const [products, setProducts] = useState(null);
  const [categories, setCategories] = useState(null);
  const [loadingProducts, setLoadingProducts] = useState(true);
  const [pagination, setPagination] = useState(null);
  const [pg, setPg] = useState(1);

  function getProducts() {
    // setLoadingProducts(true);
    // get "page" get variable from url
    const params = new URLSearchParams(window.location.search);
    const page = params.get("page");
    var url = `${Constants.api}/api/products/region/${props.location}`;
    if (page) {
      url = `${Constants.api}/api/products/region/${props.location}?page=${page}`;
    }

    console.log(url);

    axios
      .get(url, {
        Accept: "application/json",
      })
      .then((r) => {
        setPagination(r.data);
        setProducts(r.data.data);
        setLoadingProducts(false);
        // scroll to top
        window.scrollTo(0, 0);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  function addAndGoToCart(product) {
    props.addToCart(product, 1);
    navigate(`/cart`);
  }

  const setPage = (event, value) => {
    navigate(`/shop?page=${value}`);
    getProducts();
  };

  useEffect(() => {
    getProducts();
  }, [props.location, pg]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const page = params.get("page");
    if (page) {
      setPg(parseInt(page));
    } else {
      setPg(1);
    }
  }, []);

  return (
    <Container>
      <Box my={2}>
        <Typography variant="h4" align="center">
          Shop
        </Typography>
      </Box>
      <Box my={2}>
        <Divider />
      </Box>
      <Grid container>
        <Grid item xs={12} md={4}>
          <Box pr={4}>
            <CategoriesComponent />
          </Box>
        </Grid>
        <Grid item xs={12} md={8}>
          {loadingProducts ? (
            <>
              <Skeleton animation="pulse" />
              <Skeleton animation="pulse" />
              <Skeleton animation="pulse" />
            </>
          ) : (
            <>
              <ProductsLoop
                products={products}
                addAndGoToCart={addAndGoToCart}
              />
              <Box py={4}>
                <Pagination
                  count={parseInt(
                    (pagination.total / pagination.per_page).toFixed(0)
                  )}
                  style={{ justifyContent: "center", display: "flex" }}
                  justify="center"
                  color="primary"
                  onChange={(event, value) => setPage(event, value)}
                  defaultPage={pg}
                  sx={{
                    ".Mui-selected": {
                      backgroundColor: "#F8931D !important",
                    },
                  }}
                />
              </Box>
            </>
          )}
        </Grid>
      </Grid>
    </Container>
  );
};

// mapStateToProps
const mapStateToProps = (state) => {
  return {
    location: state.location,
  };
};

// mapDispatchToProps
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ addToCart }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Shop);
