import {
  Container,
  Grid,
  Box,
  Paper,
  Typography,
  Button,
  CircularProgress,
} from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import CustomTextField from "../../Components/GibsonsComponents/CustomTextField";
import Constants from "../../Components/Helpers/Constants";
import Helpers from "../../Components/Helpers/Helpers";
import useLinkStyles from "../../Styles/linkStyles";

const Login = () => {
  const linkClasses = useLinkStyles();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [formErrors, setFormErrors] = useState(false);
  const navigate = useNavigate();

  function login() {
    setLoading(true);
    setFormErrors(false);
    const fd = new FormData();
    fd.append("email", email);
    fd.append("password", password);

    axios
      .post(`${Constants.api}/api/login`, fd, {
        headers: {
          Accept: "application/json",
        },
      })
      .then((r) => {
        localStorage.setItem("user-info", JSON.stringify(r.data[0]));
        setLoading(false);
        // if returnTo localstorage is set, redirect to that page
        if (Helpers.getLocalStorage("returnTo")) {
          navigate(Helpers.getLocalStorage("returnTo"));
        } else {
          navigate("/cart");
        }
      })
      .catch((error) => {
        setFormErrors(error.response.data.error);
        setLoading(false);
      });
  }

  return (
    <Container>
      <Box sx={{ my: 4 }}>
        <Grid container justifyContent="center">
          <Grid item md={6} sm={12}>
            <Paper elevation={2}>
              <Box sx={{ my: 4, py: 3, px: 3 }}>
                <Typography variant="h4" align="center" color="secondary">
                  Log In
                </Typography>
                <Box p={2}>
                  <form>
                    <CustomTextField
                      label="Email"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      onChange={(e) => setEmail(e.target.value)}
                      type="email"
                      helperText={formErrors.email}
                      error={formErrors.email ? true : false}
                    />
                    <CustomTextField
                      label="Password"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      onChange={(e) => setPassword(e.target.value)}
                      type="password"
                      helperText={formErrors.password}
                      error={formErrors.password ? true : false}
                    />

                    <Box sx={{ pt: 2 }}>
                      <Grid container>
                        <Grid item xs={6}>
                          <Box>
                            <Link
                              to="/password-reset"
                              className={linkClasses.links}
                            >
                              Forgot password?
                            </Link>
                          </Box>
                          <Box>
                            <Link to="/register" className={linkClasses.links}>
                              Create Account
                            </Link>
                          </Box>
                        </Grid>
                        <Grid item xs={6}>
                          <Box textAlign="right">
                            {loading ? (
                              <CircularProgress size={24} />
                            ) : (
                              <>
                                <Button
                                  variant="contained"
                                  color="primary"
                                  onClick={login}
                                >
                                  LOG IN
                                </Button>
                              </>
                            )}
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </form>
                </Box>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default Login;
