import { Box, Container, Typography, Divider } from "@material-ui/core";
import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { bindActionCreators } from "redux";
import Constants from "../../Components/Helpers/Constants";
import Helpers from "../../Components/Helpers/Helpers";
import { emptyCart } from "../../Redux/Actions";
import Swal from "sweetalert2";

const PaymentSuccess = (props) => {
  const navigate = useNavigate();
  // get order id from url prameter using useParams
  const orderId = useParams().orderId;
  // console.log(orderId);
  // alert(orderId);
  // return false;
  const processOrderSuccess = () => {
    // empty cart
    props.emptyCart();

    // send email
    axios
      .post(`${Constants.api}/api/processSuccessfulPayment`, {
        orderId: orderId,
      })
      .then((r) => {
        console.log(r);

        Swal.fire({
          title: "Success",
          text: "Payment received. Your order is being processed.",
          icon: "success",
          confirmButtonText: "Ok",
        }).then(() => {
          navigate("/cart");
        });
        
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    processOrderSuccess();
  }, []);

  return (
    <Container>
      <Box py={2}>
        <Typography variant="h5" align="center">
          Payment Success
        </Typography>
      </Box>
      <Divider />
      <Box py={2}>
        <Typography variant="subtitle1" align="center">
          Your order has been successfully processed.
        </Typography>
      </Box>
    </Container>
  );
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ emptyCart }, dispatch);
};

export default connect(null, mapDispatchToProps)(PaymentSuccess);
