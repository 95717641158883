import { Button, Divider, Grid, Typography } from "@material-ui/core";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import ProductsShippingZone from "./ProductsShippingZone";
import axios from "axios";
import Constants from "../../../Components/Helpers/Constants";
import { useParams } from "react-router-dom";
import { Skeleton } from "@mui/material";
import AddProductsToZone from "./AddProductsToZone";
import ShippingZoneTitle from "./ShippingZoneTitle";
import { Link as RouterLink } from "react-router-dom";

const ShippingzoneProducts = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [shippingzone, setShippingzone] = useState(null);
  const [productsInShippingzone, setProductsInShippingzone] = useState([]);

  const getShippingZone = () => {
    axios
      .get(`${Constants.api}/api/admin/shipping-zones/list/${id}`, {
        Accept: "application/json",
      })
      .then((r) => {
        setShippingzone(r.data);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getProductsInShippingzone = () => {
    axios
      .get(`${Constants.api}/api/admin/shipping-zones/list/${id}/products`, {
        Accept: "application/json",
      })
      .then((r) => {
        setProductsInShippingzone(r.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    getShippingZone();
    getProductsInShippingzone();
  }, []);

  if (loading) {
    return (
      <>
        <Skeleton animation="wave" />
        <Skeleton animation="wave" />
        <Skeleton animation="wave" />
      </>
    );
  }

  return (
    <>
      <Box py={1}>
        <Typography align="center" variant="h5">
          Shipping Zone
        </Typography>
        <Box py={2}>
          <Typography align="center" variant="subtitle2">
            <ShippingZoneTitle
              shippingzone={shippingzone}
              getShippingZone={getShippingZone}
            />
          </Typography>
        </Box>
        <Typography align="center" variant="subtitle2">
          <b>Region:</b> {shippingzone.region}
        </Typography>
      </Box>
      <Divider />
      <Box py={1}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <AddProductsToZone
              shippingzone={shippingzone}
              getProductsInShippingzone={getProductsInShippingzone}
              productsInShippingzone={productsInShippingzone}
            />
          </Grid>
          <Grid item>
            <RouterLink
              to={`/admin/shipping-zones/list/${shippingzone.id}/edit`}
            >
              <Button variant="outlined" color="primary">
                Manage Destinations
              </Button>
            </RouterLink>
          </Grid>
        </Grid>
      </Box>
      <Divider />
      <ProductsShippingZone
        shippingzone={shippingzone}
        getProductsInShippingzone={getProductsInShippingzone}
        productsInShippingzone={productsInShippingzone}
      />
    </>
  );
};

export default ShippingzoneProducts;
