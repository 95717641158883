import { Skeleton } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import OrderWrapper from "../../Components/GibsonsComponents/OrderWrapper";
import Constants from "../../Components/Helpers/Constants";

const ShowOrder = () => {
  // get orderId from the url
  const orderId = useParams().orderId;

  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState({});

  const getOrder = () => {
    axios
      .get(`${Constants.api}/api/order/${orderId}`)
      .then((r) => {
        setOrder(r.data);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    getOrder();
  }, []);

  if (loading) {
    return (
      <>
        <Skeleton animation="wave" />
        <Skeleton animation="wave" />
        <Skeleton animation="wave" />
      </>
    );
  } else {
    return (
      <>
        <OrderWrapper order={order} />
      </>
    );
  }
};

export default ShowOrder;
