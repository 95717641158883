import {
  Box,
  CircularProgress,
  Container,
  Divider,
  Grid,
  Typography,
} from "@material-ui/core";
import axios from "axios";
import React, { useEffect } from "react";
import { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Constants from "../../Components/Helpers/Constants";

const Payment = () => {
  const orderId = useParams().orderId;
  const navigate = useNavigate();
  const [pesapalIFrame, setPesapalIFrame] = useState(null);
  const [loadedPaymentGateway, setLoadedPaymentGateway] = useState(false);
  const [paymentGatewayError, setPaymentGatewayError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  // if an order id has already been processed, e.g. if the user has already paid for this order id, the user should not be on this page

  const getPesapalIFrame = () => {
    axios
      .get(`${Constants.api}/api/payment/${orderId}`)
      .then((r) => {
        setLoadedPaymentGateway(true);
        setPesapalIFrame(r.data);
      })
      .catch((error) => {
        setLoadedPaymentGateway(false);
        setPaymentGatewayError(true);
        setErrorMessage(error);
        console.log(error);
      });
  };

  useEffect(() => {
    getPesapalIFrame();
  }, []);

  return (
    <>
      <Container>
        <Grid container justifyContent="center" alignItems="center">
          <Grid item xs={12}>
            <Typography variant="h4" align="center">
              Payment
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle2" align="center">
              Complete your order by paying with your credit card, bank or mobile money.
            </Typography>
            <Box my={4}>
              <Divider />
            </Box>
          </Grid>
          <Grid item xs={12} md={8}>
            {loadedPaymentGateway ? (
              <>
                <div dangerouslySetInnerHTML={{ __html: pesapalIFrame }} />
              </>
            ) : (
              <Box align="center">
                {/* if there is an error tell user there was an error loading the payment gateway */}
                {paymentGatewayError &&
                  <>
                    <Box mb={2}>
                      <Typography align="center">
                        There was en error. Please reload the page or get in touch with us on the contact page.
                      </Typography>
                      {/* display error code and message from api */}
                      <Typography align="center">
                        Error code: {errorMessage.message}
                      </Typography>

                    </Box>
                  </>
                }
                {/* else load the payment gateway */}
                {!paymentGatewayError &&
                  <>
                    <Box mb={2}>
                      <Typography align="center">
                        Please wait as we load the payment gateway.
                      </Typography>
                    </Box>
                    <CircularProgress color="primary" textalign="center" />
                  </>
                }
              </Box>
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Payment;
