import {
  Box,
  Divider,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";
import { Skeleton } from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { NavLink } from "react-router-dom";
import useStyles from "../../../Components/GibsonsComponents/LayoutCSS";
import Constants from "../../../Components/Helpers/Constants";

const ViewOrders = () => {
  const classes = useStyles();

  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);

  const user = JSON.parse(localStorage.getItem("user-info"));
  const getOrders = () => {
    axios
      .get(`${Constants.api}/api/orders/all`)
      .then((res) => {
        setOrders(res.data);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    getOrders();
  }, []);

  if (loading) {
    return (
      <>
        <Skeleton animation="wave" />
        <Skeleton animation="wave" />
        <Skeleton animation="wave" />
      </>
    );
  }
  return (
    <>
      <Box py={1}>
        <Typography align="center" variant="h5">
          Orders
        </Typography>
      </Box>
      <Divider />
      <Box py={1}>
        <TableContainer>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow sx={{ textTransform: "uppercase" }}>
                <TableCell>Order #</TableCell>
                <TableCell align="right">Date</TableCell>
                <TableCell align="right">Customer</TableCell>
                <TableCell align="right">Shipping</TableCell>
                <TableCell align="right">Total</TableCell>
                <TableCell align="right">Payment Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orders.map((order) => {
                if (order.order_items.length > 0 && order?.payment != null) {
                  // order?.payment?.payment_status
                  const currency = order.order_items[0].product.currency;
                  if (order?.payment?.payment_status === "INCOMPLETE") {
                    return null;
                  }
                  return (
                    <TableRow key={order.id}>
                      <TableCell>
                        <Typography variant="body2">
                          <NavLink
                            to={`/admin/orders/${order.id}`}
                            className={classes.buttonTypeLink}
                            target="_blank"
                          >
                            {order.id}
                          </NavLink>
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Typography variant="body2">
                          {order.created_at}
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Typography variant="body2">
                          {order.user.first_name} {order.user.last_name}
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Typography variant="body2">
                          {Constants.currency(currency, order.shippingTotal)}
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Typography variant="body2">
                          {Constants.currency(
                            currency,
                            order.total + order.shippingTotal
                          )}
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Typography variant="body2">
                          {order?.payment?.payment_status == "PAID"
                            ? "PAID"
                            : "PENDING"}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  );
                }
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};

export default ViewOrders;
