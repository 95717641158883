import React from "react";

const MapDetails = (props) => {
  return (
    <div>
        {props.children}
    </div>
  );
};

export default MapDetails;
