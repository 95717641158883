import {
  Button,
  ButtonBase,
  Grid,
  List,
  ListItem,
  Modal,
  Typography,
} from "@material-ui/core";
import { EditLocation } from "@material-ui/icons";
import { Close } from "@mui/icons-material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import modalStyle from "../../Styles/ModalStyles";
import useStyles from "./LayoutCSS";
import { emptyCart } from "../../Redux/Actions";
import { setLocation } from "../../Redux/Actions";
import Swal from "sweetalert2";
import axios from "axios";
import Constants from "../Helpers/Constants";
import { useNavigate } from "react-router-dom";

const ChooseLocation = (props) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleLocation = (loc) => {
    // if the new location is not different from the current location, do nothing
    if (loc == props.location) {
      handleClose();
      return false;
    }
    if (loc == "usa") {
      window.location.href = "https://gibscoffee.com";
      return false;
    }

    handleClose();
    // use Swal to confirm if user wants to proceed
    Swal.fire({
      title: "Are you sure?",
      text: "You will be redirected to the new location and your cart will be emptied so you can shop for products from your location.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, change it!",
    }).then((result) => {
      if (result.value) {
        navigate("/shop");
        
        // get shipping rates for the new location
        getShippingrates(loc);

        props.setLocation(loc);
        props.emptyCart();
        // redirect to shop
      } else {
        // do nothing
      }
    });
  };

  const getShippingrates = (loc = null) => {
    if (loc == null) {
      loc = props.location;
    }
    axios
      .get(`${Constants.api}/api/admin/shippingrates/region/${loc}`, {
        Accept: "application/json",
      })
      .then((r) => {
        console.log(r.data);
        // store rates in localstorage
        localStorage.setItem("shippingRates", JSON.stringify(r.data));
      })
      .catch((e) => {
        console.log(e)
        console.log(e?.response?.data);
      });
  };

  useEffect(() => {
    // if there are no shippingrates stored in localstage, store them
    if (!localStorage.getItem("shippingRates")) {
      getShippingrates();
    }
  }, []);

  return (
    <>
      <ButtonBase className={classes.changeLocation} onClick={handleOpen}>
        <EditLocation fontSize="small" />
        <Typography variant="body2">
          You are buying from our shop in{" "}
          <b>{props.location && props.location.toUpperCase()}</b>. Click here to
          change your location.
        </Typography>
      </ButtonBase>

      <Modal open={open} onClose={handleClose}>
        <Box sx={modalStyle}>
          <Grid container>
            <Grid item xs={11}>
              <Typography variant="h6" align="center">
                Change Location
              </Typography>
              <Typography variant="body2" align="center">
                Select where you're buying from. <br /> Your cart will be
                emptied if you are swaping from one location to another.
              </Typography>
            </Grid>
            <Grid item xs={1} align="right">
              <Button onClick={handleClose}>
                <Close />
              </Button>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <List>
                <ListItem>
                  <Button
                    color="primary"
                    variant="outlined"
                    onClick={() => handleLocation("kenya")}
                  >
                    Kenya
                  </Button>
                </ListItem>
                <ListItem>
                  <Button
                    color="primary"
                    variant="outlined"
                    onClick={() => handleLocation("rest of the world")}
                  >
                    International - Africa, Europe, &amp; The rest of the world
                  </Button>
                </ListItem>
                <ListItem>
                  <Button
                    color="primary"
                    variant="outlined"
                    onClick={() => handleLocation("usa")}
                  >
                    USA &amp; North America
                  </Button>
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};

// mapStateToProps
const mapStateToProps = (state) => {
  return {
    location: state.location,
  };
};

// mapDispatchToProps
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ emptyCart, setLocation }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ChooseLocation);
