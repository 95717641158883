import { makeStyles } from "@material-ui/core";


const useLinkStyles = makeStyles((theme) => {
  return {
    links: {
      color: theme.palette.text.secondary,
      fontWeight: "bold",
      "&:hover": {
        color: theme.palette.primary.main,
      },
    },
  };
});


export default useLinkStyles;
